import Vue from 'vue/dist/vue.esm'
import tippy from 'tippy.js'
import iconv from 'iconv-lite'
import { rubyTimezones } from '@/helpers/ruby_timezones'
import { isEmpty } from 'lodash'
import { DECIMAL_TRANSFORM } from '@/modules/utils/_core/const'
import { GLOBAL_BRANCH_TYPE } from '@/_global_scripts/GLOBAL_CONSTS'
import { DateHelpers } from '@/helpers/DateHelpers'
import { ArrayHelpers } from '@/helpers/ArrayHelpers'

window.Utils = (function () {
  // resulting object
  const ut = {}

  ut.init = function () {
    $(window)
      .on('resize', function () {
        ut.resizeToFullscreen()
      })
  }

  ut.baseDateFormat = 'YYYY-MM-DD'
  ut.momentDateFormat = gon.localization.date_format.toUpperCase()
  ut.momentDateTimeFormat = gon.localization.date_format.toUpperCase() + ' ' + 'HH:mm'
  ut.elDatePickerDateFormat = 'dd MMM yyyy'
  ut.elDateFormat = gon.localization.date_format.replace('mm', 'MM')
  ut.elTimeFormat = 'HH:mm:ss'
  ut.rubyDateFormat = 'YYYY-MM-DD'
  ut.shortDateTimeFormat = 'DD MMM YYYY, HH:mm'
  ut.shortDateFormat = 'DD MMM YYYY'
  ut.longDateFormat = 'DD MMMM YYYY'
  ut.onlyYearDateFormat = 'YYYY'
  ut.getFormattedDate = (date, format = ut.momentDateFormat, fromFormat) => moment(date, fromFormat).format(format)
  ut.getBaseFormattedDate = (date = undefined, fromFormat = undefined) => ut.getFormattedDate(date, ut.baseDateFormat, fromFormat)
  ut.getTodayTomorrowYesterday = DateHelpers.getTodayTomorrowYesterday
  ut.getFormattedTime = (time, format = ut.elTimeFormat) => moment(time).format(format)

  ut.decimalCharacter = gon.localization.currency.decimal_character
  ut.defaultSeparators = Object.freeze(['.', ','])
  ut.getRightDecimalSeparator = (char) => DECIMAL_TRANSFORM[ut.decimalCharacter][char]

  ut.onlyNumbersKeydownTemplate = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'Backspace',
    'Delete',
    '-',
    '.',
    ',',
    'Tab',
  ]

  ut.isDevelopment = () => gon.application.env === 'development'
  ut.isProduction = () => gon.application.env === 'production'
  ut.isTrustedOrigin = (origin) => [
    'https://develop.medods.ru',
    'https://clusterdevelop.medods.ru',
    'https://stage.medods.ru',
  ].includes(origin)

  ut.branch = {
    isMed: gon.application.branch === GLOBAL_BRANCH_TYPE.MED || gon.application.branch === GLOBAL_BRANCH_TYPE.CUSTOM,
    isDent: gon.application.branch === GLOBAL_BRANCH_TYPE.DENT || gon.application.branch === GLOBAL_BRANCH_TYPE.CUSTOM,
    isCustom: gon.application.branch === GLOBAL_BRANCH_TYPE.CUSTOM,
  }

  // replace standart rails confirm dialogue
  const originalAllow = $.rails.allowAction

  $.rails.allowAction = function (link) {
    if (!link.attr('data-confirm')) return originalAllow(link)

    if (link.data('permitted')) return true
    const text = link.data('confirm')

    bootbox.confirmYN(text, function (approved) {
      if (!approved) return
      link.removeAttr('data-confirm')
      link.data('confirm', null)
      link.get(0).click()
    })

    return false
  }

  /**
   *
   * @param {'Bit' | 'Kb' | 'Mb'} unit
   * @returns {number}
   */
  ut.getMaxFileSize = (unit = 'Mb') => {
    const fileSize = gon.application.files.max_size
    const unitMap = {
      Bit: 0,
      Kb: 1,
      Mb: 2,
    }

    return Math.floor(fileSize / Math.pow(1024, unitMap[unit]))
  }

  ut.maxFileSizeMessage = function (size) {
    const maxSize = size || ut.getMaxFileSize()

    return `${t('errors.file_size_is_too_large')}. ${t('max')} ${maxSize} ${t('mbyte')}`
  }

  /**
   * TODO: Ликвидировать по возможности
   */
  ut.select2ButtonUpdate = function () {
    $('.s2b-noempty').select2Buttons({ noEmpty: true })
    $('.s2b-noempty-undoable').select2Buttons({ noEmpty: true, undo: true })
    $('.s2b').select2Buttons()
  }

  ut.tippyConfig = {
    content (reference) {
      const title = reference.getAttribute('title')

      reference.removeAttribute('title')

      return title
    },
    duration: 200,
    arrow: true,
    allowHTML: true,
    trigger: 'mouseenter focus',
    animation: 'scale',
    theme: 'light-border',
  }

  ut.updateTooltipsInContainer = function (container) {
    if (!container.querySelectorAll) { return [] }

    return tippy(container.querySelectorAll('.tooltip-bottom, [title]:not([title=""])'), ut.tippyConfig)
  }

  ut.epicUpdateTooltipsContainer = function (params) {
    let tippyConfig = ut.tippyConfig
    const targets = $(params.selector).find('[title]:not([title=""])').get()

    if (params.config) tippyConfig = params.config

    if (params.extendDefaultConfig) tippyConfig = Object.assign(ut.tippyConfig, params.extendDefaultConfig)

    return tippy(targets, tippyConfig)
  }

  ut.updateTooltips = function (container = document) {
    if (!container) {
      return console.info('Не указан контейнер для обновления тултипов')
    }

    ut.updateTooltipsInstances(container, '[title]:not([title=""])')
  }

  /**
   * Тултипное замыкание
   * @returns {(function(*=, *=): void)|*}
   */
  ut.updateTooltipsInstances = (() => {
    const tippyInstances = {}

    /**
     * Обновление тултипов в контейнере, по умолчанию в document
     * @param {Document | Element} container
     * @param {Object} config
     */
    return function (
      container = document,
      selector = '[data-tooltip]:not([data-tooltip=""])',
      config = {}
    ) {
      let containerName = 'document'
      if (container) {
        const el = arguments[0]
        containerName = `${el?.localName || el?.nodeName}#${el?.id || ''}.${el?.className || ''}`
      }

      if (Array.isArray(tippyInstances[containerName])) {
        tippyInstances[containerName].forEach((_, index) => {
          if (tippyInstances[containerName][index].reference && container === document) {
            tippyInstances[containerName][index].reference.title = tippyInstances[containerName][index]?.props?.content
          }

          tippyInstances[containerName][index].destroy()
        })
      }

      tippyInstances[containerName] = []

      const elementsTitle = container.querySelectorAll(selector)
      elementsTitle.forEach((item) => {
        tippyInstances[containerName]
          .push(tippy(item, { content: item.dataset.tooltip, ...config }))
      })
    }
  })()

  /**
   *
   * @param {string} n currency string convert (without currency sign)
   */
  ut.currencyToNumber = function (str) {
    const { currency } = gon.localization
    str = str.replace(/\s+/g, '')
    if (currency) {
      return +str
        .replace(currency.decimal_character, '.')
    }

    return +str
  }

  /**
   *
   * @param {number} num number to convert
   * @return {string}
   */
  ut.numberToLocaleCurrency = function (num) {
    if (gon.localization.locale) {
      return num.toLocaleString(gon.localization.locale, {
        style: 'currency',
        currency: gon.localization.currency.code_string,
      })
    }

    return num.toFixed(2) + ' ' + Utils.currencySign()
  }

  ut.clickableBtn = function () {
    $('body').on('click', '.clickable-btn:not(.feature_not_purchased)', function (e) {
      const link = $(this).data('href')
      Turbolinks.visit(link)
    })
  }

  ut.dateRubyToHuman = function (date) {
    if (!date) {
      return ''
    }
    const da = date.split('-')

    return da[2] + '.' + da[1] + '.' + da[0]
  }

  ut.dateJStoRuby = function (date) {
    let day = date.getDate()
    let month = date.getMonth() + 1
    const year = date.getFullYear()
    if (day < 10) {
      day = '0' + day
    }
    if (month < 10) {
      month = '0' + month
    }

    return year + '-' + month + '-' + day
  }

  ut.dateRubyToJS = function (date) {
    if (!date) return ''

    return new Date(date)
  }

  ut.timeRangeMinutes = function (startTime, endTime) {
    return Utils.toMoment(endTime).diff(Utils.toMoment(startTime), 'minutes')
  }

  ut.clearString = function (str) {
    return str.replace(/&nbsp;/g, ' ').trim()
  }

  ut.getTZByRubyTZ = function (rubyTimezone) {
    return rubyTimezones[rubyTimezone]
  }

  ut.getTimezonedDateTime = function (date) {
    const dt = date || new Date()

    return moment(dt)
      .tz(ut.getTZByRubyTZ(gon.application.timezone)).format()
  }

  ut.getOffsetBetweenOriginTZAndLocal = function (date) {
    const dt = date || new Date()

    return moment(dt).utcOffset() - moment(ut.getTimezonedDateTime(dt))._tzm
  }

  /**
   * @param {Date} date
   * @param {Number} customFactor - множитель -1/1
   * @return {Date}
   */
  ut.getShiftedDateTimeByOffset = function (date, customFactor = 1) {
    const dt = date || new Date()
    const dateShift = ut.getOffsetBetweenOriginTZAndLocal(dt) * customFactor

    return moment(dt)
      .utcOffset(dateShift)
      ._d
  }

  // TODO: move it somewhere
  ut.initCalendar = function () {
    $('.date_start').datepicker({
      language: gon.localization.locale,
      weekStart: gon.localization.day_week_start,
      format: gon.localization.date_format,
      orientation: 'bottom',
      startDate: '01.01.2013',
      todayBtn: 'linked',
      todayHighlight: true,
    })

    $('.date_end').datepicker({
      language: gon.localization.locale,
      weekStart: gon.localization.day_week_start,
      format: gon.localization.date_format,
      orientation: 'bottom',
      startDate: '01.01.2013',
      todayBtn: 'linked',
      todayHighlight: true,
    })

    $('.date_start').inputmask('date', { placeholder: '__.__.____' })
    $('.date_end').inputmask('date', { placeholder: '__.__.____' })

    $('.date_start').on('changeDate', function (ev) {
      $(this).datepicker('hide')
    })

    $('.date_end').on('changeDate', function (ev) {
      $(this).datepicker('hide')
    })
  }

  const resizeElement = function (container) {
    const control = $('.items-sort-control-area')
    let offset = container.data('offset')

    offset = (offset === undefined) ? offset : parseInt(offset)
    let height = $('.app-content').height() - control.height() - offset

    const notBiggerThanPtr = container.data('not-bigger-than')
    if (notBiggerThanPtr) {
      const childHeight = $(notBiggerThanPtr).height()
      if (childHeight === 0) {
        const retries = container.data('retry') || 0

        if (retries < 5) {
          container.data('resize-retries', retries + 1)

          return setTimeout(resizeElement.bind(this, container), 10)
        }

        container.data('resize-retries', 0)
      }

      if (childHeight < height) height = undefined
    }

    container.height(height)
  }

  ut.resizeToFullscreen = function () {
    $('.items-list-container').each(function (i, container) {
      resizeElement($(container))
    })
  }

  ut.isInvalidBrowser = function () { return !bowser.blink && !bowser.webkit }

  ut.toMoment = function (time) {
    const hours = time.split(':')[0]
    const minutes = time.split(':')[1]

    return moment().startOf('day').hours(hours).minute(minutes)
  }

  /**
   * TODO: wtf?
   * @param params
   * @returns {*}
   */
  ut.ajaxUpdate = function (params) {
    $.ajax({
      url: params.url,
      method: 'PATCH',
      data: params.data,
      success: params.success,
      error (res) {
        if (res.status === 500) {
          return Utils.reportError(
            'ut.ajaxUpdate',
            undefined,
            t('errors.server_error')
          )(res)
        }

        if (params.error) return params.error(res)
        const errors = res.responseJSON
        if (errors._deletion) {
          bootbox.alert(errors._deletion.join('. '))
        }
      },
    })
  }

  /**
   * TODO: wtf?
   * @param params
   * @returns {*}
   */
  ut.ajaxDelete = function (params) {
    $.ajax({
      url: params.url,
      method: 'delete',
      success: params.success,
      error (res) {
        if (res.status === 500) {
          return Utils.reportError(
            'ut.ajaxDelete',
            undefined,
            t('errors.server_error')
          )(res)
        }

        if (params.error) return params.error(res)
        const errors = res.responseJSON
        if (errors._deletion) {
          bootbox.alert(errors._deletion.join('. '))
        }
      },
    })
  }

  ut.askDestroy = function (params) {
    bootbox.confirmDeletion(t('delete') + ' ' + params.title + '?', function (res) {
      if (res) {
        Utils.ajaxDelete(params)
      }
    }, { title: t('confirm_delete') })
  }

  /**
   * TODO: зарефакторить, слишком длинное
   */
  ut.fixTabPagination = function () {
    // Hack from StackOverflow
    $('.nav-tabs > li > a').click(function (e) {
      // getting tab id
      const href = $(e.target).attr('href')
      if (!href) {
        return
      }
      const id = href.substr(1)

      // позволит использовать несколько панелей сразу
      $('.nav-tabs').children('li').removeClass('active')

      // bad fix for bad hack with tab links
      if (href[0] !== '/') {
        $(this).tab('show')
      }

      // change hash value for all pages
      $('ul.pagination > li > a').each(function (i, pageAnchor) {
        pageAnchor.hash = id
      })
    })

    // assign tab id to location hash
    $('ul.nav-tabs > li > a').on('shown.bs.tab', function (e) {
      const id = $(e.target).attr('href').substr(1)
      window.location.hash = id
    })

    // open initial hash
    const hash = window.location.hash
    $('.nav-tabs > li > a[href="' + hash + '"]').tab('show')

    // UPDATE
    $('ul.pagination > li > a').each(function (i, pageAnchor) {
      pageAnchor.hash = hash
    })
  }

  ut.getQueryParams = function () {
    const queryString = {}
    const query = window.location.search.substring(1)
    if (!query) {
      return queryString
    }
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      // If first entry with this name
      if (typeof queryString[pair[0]] === 'undefined') {
        queryString[pair[0]] = decodeURIComponent(pair[1])
        // If second entry with this name
      } else if (typeof queryString[pair[0]] === 'string') {
        const arr = [queryString[pair[0]], decodeURIComponent(pair[1])]
        queryString[pair[0]] = arr
        // If third or later entry with this name
      } else {
        queryString[pair[0]].push(decodeURIComponent(pair[1]))
      }
    }

    return queryString
  }

  ut.moneyRound = function (val) {
    return Math.round(parseFloat(val) * 100) / 100
  }

  ut.newGUID = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
      .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : (r & 0x3 | 0x8)

        return v.toString(16)
      })
  }

  // stollen from internet
  ut.fromRoman = function (str) {
    let result = 0
    // the result is now a number, not a string
    const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
    const roman = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L',
      'XL', 'X', 'IX', 'V', 'IV', 'I']
    for (let i = 0; i <= decimal.length; i++) {
      while (str.indexOf(roman[i]) === 0) {
        // checking for the first characters in the string
        result += decimal[i]
        // adding the decimal value to our result counter
        str = str.replace(roman[i], '')
        // remove the matched Roman letter from the beginning
      }
    }

    return result
  }

  ut.currencySign = function () {
    const currency = gon.application.currency
    if (currency) {
      return currency.symbol_character
    }

    // if you have no currency => just use little money bags
    return '💰'
  }

  /**
   * Сравнение строк для сортировки на фронте
   * @param {String} str1
   * @param {String} str2
   * @returns {number|boolean}
   */
  ut.stringsCompare = function (str1, str2) {
    return str1 < str2 ? -1 : str1 > str2
  }

  ut.isPromise = function (obj) {
    return Promise.resolve(obj) === obj
  }

  ut.inputmaskPresets = {
    barePercentage: [
      'Regex', { regex: '^[0-9][0-9]?(?:\\.[0-9]{1,2})?$|^100$' },
    ],
    currency: [
      'currency', {
        prefix: '',
        groupSeparator: gon.localization.currency.grouping_character,
        rightAlign: false,
        autoUnmask: true,
      },
    ],
  }

  ut.calendarDefaultRanges = function () {
    const ranges = {}
    ranges[t('date_rage_picker.today')] = [moment(), moment()]
    ranges[t('date_rage_picker.yesterday')] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
    ranges[t('date_rage_picker.seven_days')] = [moment().subtract(6, 'days'), moment()]
    ranges[t('date_rage_picker.thirty_days')] = [moment().subtract(29, 'days'), moment()]
    ranges[t('date_rage_picker.ninety_days')] = [moment().subtract(89, 'days'), moment()]
    ranges[t('date_rage_picker.ninety_days')] = [moment().subtract(89, 'days'), moment()]
    ranges[t('date_rage_picker.this_month')] = [moment().startOf('month'), moment().endOf('month')]
    ranges[t('date_rage_picker.last_month')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    ranges[t('date_rage_picker.this_year')] = [moment().startOf('year'), moment().endOf('year')]
    ranges[t('date_rage_picker.last_year')] = [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]

    return ranges
  }

  ut.getDateRangePickerConfig = (config = {}) => {
    const defaults = {
      linkedCalendars: false,
      autoApply: false,
      ranges: {},
      locale: {},
      applyClass: 'btn-primary',
    }

    defaults.ranges = ut.calendarDefaultRanges()

    defaults.locale.format = 'D MMMM YYYY'
    defaults.locale.separator = ' - '
    defaults.locale.applyLabel = 'OK'
    defaults.locale.cancelLabel = t('date_rage_picker.cancel')
    defaults.locale.fromLabel = t('date_rage_picker.from')
    defaults.locale.toLabel = t('date_rage_picker.to')
    defaults.locale.customRangeLabel = t('date_rage_picker.custom')
    defaults.locale.firstDay = gon.localization.day_week_start

    return $.extend(true, {}, defaults, config)
  }

  ut.elDatePickerDefaultRanges = function () {
    const shortcuts = Object
      .entries(ut.calendarDefaultRanges())
      .map(([key, [start, end]]) => {
        return {
          text: key,
          onClick: (picker) => picker.$emit('pick', [start, end]),
        }
      })

    return shortcuts
  }

  /**
   * TODO: Требуется найти где используется и уничтожить
   * @deprecated
   * @param data
   * @return {*|jQuery|HTMLElement}
   */
  ut.userDropdownTemplate = function (data) {
    const userId = data.id

    const url = userId
      ? Routes.user_avatar_path(userId, { tag: $(data.element).attr('data-avatar-tag'), version: 'thumb16' })
      : '/default_avatars/unisex/16.svg'

    return $('<span><img src="' + url + '" class="avatar-select2-option-img">' + data.text + '</span>')
  }

  /**
   * TODO: Эта дичь только в счетах, требуется удалить после переписывания
   * Исполняется во время выбора реферала
   * @deprecated
   * @param data
   * @return {*|jQuery|HTMLElement}
   */
  ut.referralDropdownTemplate = function (data) {
    const userId = data.user_id || $(data.element).attr('data-user-id')
    const tag = data.avatar_tag || $(data.element).attr('data-avatar-tag')
    const url = userId && userId !== 'null'
      ? Routes.user_avatar_path(userId, { tag, version: 'thumb16' })
      : '/default_avatars/unisex/16.svg'

    return $('<span><img src="' + url + '" class="avatar-select2-option-img">' + data.text + '</span>')
  }

  /**
   * TODO: Требуется найти где используется помимо счетов и уничтожить
   * @deprecated
   * @param data
   * @return {*|jQuery|HTMLElement}
   */
  ut.referralDropdownTemplateAjax = function (data) {
    const userId = data.user_id || $(data.element).attr('data-user-id')
    const tag = data.avatar_tag || $(data.element).attr('data-avatar-tag')

    const url = userId && userId !== 'null'
      ? Routes.user_avatar_path(userId, { tag, version: 'thumb16' })
      : '/default_avatars/unisex/16.svg'

    return $('<span><img src="' + url + '" class="avatar-select2-option-img">' + data.text + '</span>')
  }

  ut.tinymceTableDefaults = {
    border: '1',
  }

  ut.tinymceTableStyles = {
    'border-collapse': 'collapse',
    'table-layout': 'fixed',
    width: '100%',
    minWidth: '100px',
  }

  ut.restoreScrollPosition = function () {
    if (gon.page.params.scrollPositions) {
      const scrollPositions = gon.page.params.scrollPositions
      for (const id in scrollPositions) {
        $('#' + id).scrollTop(scrollPositions[id])
      }
    }
  }

  ut.setActiveTr = function () {
    if (gon.page.params.activeTr) {
      $('#' + gon.page.params.activeTr).addClass('active')
    }
  }

  /**
   * TODO: удалить после переписывания форм клиента
   * @param elem
   * @param params
   */
  ut.select2ClientAjax = function (elem, params) {
    params = params || {}

    $(elem).select2({
      containerCssClass: params.containerCssClass,
      width: '100%',
      dropdownAutoWidth: true,
      multiple: params.multiple || false,
      minimumInputLength: 3,
      ajax: {
        url: '/clients/search_select2',
        dataType: 'json',
        delay: 250,
      },
    })
  }

  ut.includeLegalRepresentativesVar = (value) => {
    const regexp = /legal_representative(&amp;|&)\.|client(&amp;|&)\.parent_name|client(&amp;|&)\.formatted_parent_birthdate/

    return regexp.test(value)
  }

  ut.includeDocumentDatesVar = (value) => {
    const regexp = /document(&amp;|&)\.(formatted_date_start|formatted_date_end)/

    return regexp.test(value)
  }

  ut.includeLegalRepresentativesOrClientVar = (value) => {
    const regexp = /client(&amp;|&)\.parent_or_client_full_name/

    return regexp.test(value)
  }

  ut.formatClosestDates = function (date, finalFormat, initialFormat = 'YYYY-MM-DD') {
    let title = ''
    let className = ''
    const today = moment().format(finalFormat)
    const yesterday = moment().subtract(1, 'days').format(finalFormat)
    const tomorrow = moment().add(1, 'days').format(finalFormat)
    const formattedDate = moment(date, initialFormat).format(finalFormat)

    switch (true) {
      case formattedDate === today:
        title = T.today
        className = 'date_today'
        break
      case formattedDate === yesterday:
        title = T.yesterday
        className = 'date_yesterday'
        break
      case formattedDate === tomorrow:
        title = T.tomorrow
        className = 'date_tomorrow'
        break
      default:
        title = formattedDate
        break
    }

    return {
      title,
      className,
    }
  }

  /**
   * TODO: Выпилить, использовать Services.security
   */
  ut.ACTIONS = Object.freeze({
    MANAGE: 'Manage',
    VIEW: 'View',
    MANAGE_SELF: 'ManageSelf',
  })

  /**
   * TODO: Выпилить, использовать Services.security
   * @param action
   * @param model
   * @returns {boolean}
   */
  ut.can = function (action, model) {
    const currentUserPermissions = gon.application.security.current_user_permissions
    let condition = false

    if (currentUserPermissions) {
      switch (action) {
        case ut.ACTIONS.MANAGE:
        case 'manage':
          condition =
            (currentUserPermissions.all && currentUserPermissions.all.includes(ut.ACTIONS.MANAGE)) ||
            (currentUserPermissions[model] && currentUserPermissions[model].includes(ut.ACTIONS.MANAGE))
          break
        case ut.ACTIONS.VIEW:
        case 'view':
          condition =
            (currentUserPermissions.all && currentUserPermissions.all.includes(ut.ACTIONS.MANAGE)) ||
            (currentUserPermissions[model] && currentUserPermissions[model].includes(ut.ACTIONS.MANAGE)) ||
            (currentUserPermissions[model] && currentUserPermissions[model].includes(ut.ACTIONS.VIEW))
          break
        case ut.ACTIONS.MANAGE_SELF:
          condition =
            (currentUserPermissions.all && currentUserPermissions.all.includes(ut.ACTIONS.MANAGE_SELF)) ||
            (currentUserPermissions[model] && currentUserPermissions[model].includes(ut.ACTIONS.MANAGE_SELF)) ||
            (currentUserPermissions[model] && currentUserPermissions[model].includes(ut.ACTIONS.VIEW))
          break
        default:
          throw new Error('Invalid action')
      }
    }

    return Boolean(condition)
  }

  ut.downloadExportFile = function (body, fileName, mimeType = 'text/csv') {
    const extension = mimeType.split('/')[1]
    let blob

    if (gon.application.export_encoding === 'win1251') {
      const byteArrayWin1251 = iconv.encode(body, 'win1251')
      blob = new Blob([byteArrayWin1251], { type: `${mimeType};charset=windows-1251` })
    } else {
      blob = new Blob([body], { type: `${mimeType};charset=utf-8` })
    }

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `${fileName}.${extension}`
    link.click()
    link.remove()
  }
  /**
   * Inherits prototype of the base class.
   * Use this to extend from es5-style classes,
   * because Webpack doesn't do this for es6 classes and you can't use `instanceof` checks
   *
   * @param {Function} subclass
   * @param {Function} baseClass
   */
  ut.inherit = function (subclass, baseClass) {
    subclass.prototype = Object.create(baseClass.prototype)
    Object.defineProperty(subclass.prototype, 'constructor', {
      value: subclass,
      enumerable: false,
      writable: true,
    })
  }

  /**
   * Ищет в объекте значение по указанным ключам, возвращает первое найденное.
   * @param {object} target
   * @param {string[]} keys
   * @param {*} defaultValue
   * @returns {*}
   */
  ut.findValueByAliases = function (target, keys, defaultValue) {
    for (let i = 0; i < keys.length; i++) {
      const hasValue = Object.prototype.hasOwnProperty.call(target, keys[i])
      const value = target[keys[i]]
      if (hasValue && target[keys[i]] !== undefined) {
        return value
      }
    }

    return defaultValue
  }

  /**
   * Создаст Vue app на основе конфига
   * @param {Object} options
   * @returns {*}
   */
  ut.initVueInBody = (options) => {
    const { el } = options

    if (!el) {
      throw new Error('missing el')
    }

    if ($(el).length > 0) {
      throw new Error('el already exists')
    }

    $('body').append($(`<div id="${el.substring(1)}"></div>`))

    return new Vue(options)
  }

  /**
   * @param {Number|String} value
   * @param {Number} [fractionDigits]
   * @return {Number}
   */
  ut.toMoney = (value, fractionDigits = 2) =>
    parseFloat(parseFloat(value).toFixed(fractionDigits) || 0) || 0

  /**
   * Преобразование массива в мапу
   *
   * @param {Array} items
   * @param {String} keyAttribute
   * @return {Object}
   */
  ut.arrayToMap = ArrayHelpers.arrayToMap

  /**
   * Упрощенная замена тернарному оператору
   * @param {any} condition
   * @param {any} trueResult
   * @param {any} [falseResult]
   * @return {any|null}
   */
  ut.ternary = (condition, trueResult, falseResult = null) => {
    return condition ? trueResult : falseResult
  }

  /**
   * Получение количества страниц по количеству элементов и максимальной вместимости страницы
   * @param total
   * @param limit
   * @return {number}
   */
  ut.getTotalPages = (total = 1, limit = 1) =>
    Math.ceil(total / limit)

  /**
   * Вставка строки в строку
   * @param {String} str
   * @param {String} insertedStr
   * @param {Number} position
   * @return {String}
   */
  ut.insertToString = (str, insertedStr, position) =>
    str.slice(0, position) + insertedStr + str.slice(position)

  /**
   * Проверка на пустое значение
   * @param value
   * @param {{
   *   validZero?: boolean
   * }} options
   */
  ut.getValidationErrors = (value, options = {}) => {
    if (options.validZero && value === 0) { return [] }

    const emptyText = t('errors.filled?')

    if (typeof value === 'string') { value = value.trim() }
    if (!value) { return [emptyText] }
    if (typeof value === 'object' && !(value instanceof Date) && isEmpty(value)) {
      return [emptyText]
    }

    return []
  }

  /**
   * Извлечение цифр из строки
   * @param str {String}
   * @returns {String}
   */
  ut.extractDigits = (str) => {
    return str.replace(/\D/g, '')
  }

  /**
   * Открытие ссылки в новом окне
   * @param url {String}
   */
  ut.openInNewTab = (url) => {
    window.open(url, '_blank')
  }

  /**
   * Генератор уникального id
   * @returns {function(): number}
   */
  ut.getIdGenerator = () => {
    let id = 0

    return () => ++id
  }

  /**
   * Применяет маску к значению
   * @param value {String}
   * @param mask {String}
   * @param opts {{
   *   maskCharacter?: string
   * }}
   * @returns {string}
   */
  ut.applyMask = (
    value,
    mask,
    {
      maskCharacter,
    } = {
      maskCharacter: '#',
    }) => {
    if (!value) { return '' }

    value = String(value)

    let result = ''
    let valueIdx = 0

    for (const char of mask) {
      if (char === maskCharacter) {
        result += value[valueIdx++]

        if (!value[valueIdx]) { break }
      } else {
        result += char
      }
    }

    return result
  }

  ut.joinTruthy = (items = [], separator = ' ', opts = {}) => {
    return items
      .filter(Boolean)
      .join(separator)
  }

  /**
   * Возвращает текст "Пожалуйста, введите не менее {minValueLength} символов"
   * @param value {String}
   * @param minValueLength {Number}
   * @returns {string}
   */
  ut.getPleaseEnterLeast = (value, minValueLength) => {
    return [
      t('please_enter_least'),
      minValueLength - value.length,
      t('symbols'),
    ].join(' ')
  }

  /**
   * Возвращает html-код тултипа.
   * Атеншон! Чтобы стилизовать эту хрень нужно, чтобы стили не были вложенными тк сам тултип монтируется в низ body
   *
   * @param content {string}
   * @param options {{
   *   cssClasses?: string
   * }}
   * @returns string
   */
  ut.getTooltipWrapper = (content, options = {}) => {
    return `<Tippy class="tooltip-wrapper ${options.cssClasses || ''}">${content}</Tippy>`
  }

  /**
   * Проверяет является ли аргумент конструктором
   * @param PotentialConstructor
   * @param params {unknown}
   * @returns {boolean}
   */
  ut.isConstructor = (PotentialConstructor, ...params) => {
    try {
      // eslint-disable-next-line no-new
      new PotentialConstructor(...params)
    } catch {
      return false
    }

    return true
  }

  /**
   * @param urlString
   * @return {boolean}
   */
  ut.isValidUrl = (urlString) => {
    try {
      const url = new URL(urlString)

      return urlString === url.href || urlString === url.origin
    } catch (err) {
      return false
    }
  }

  return ut
}())
