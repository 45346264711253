import formatter from '@/lib/formatters/formatter'

Report.drawChart = (function () {
  var testArr = []
  var self = {}
  var chart_container = 'chart_container'
  const titleFontSize = 15

  var hasContainer = function () {
    return !!$('#' + chart_container).length
  }

  self.admissionAssessmentDynamics = function (values) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'area',
      },
      title: {
        style: {
          fontSize: titleFontSize,
        },
        text: t('reports.long_titles.admission_assessment_dynamics'),
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      yAxis: [{
        title: {
          text: t('reports.average_assessment'),
        },
      }],
      series: [{
        name: 'TEST_NAME',
        color: '#3FAECA',
        data: values,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y:.0f}', // 0 decimal
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
            textOutline: false,
          },
        },
      }],
      tooltip: {
        pointFormat: t('reports.average_assessment') + ': <b>{point.y:.0f}</b>',
      },
    })

    removeCopyright()
  }

  self.npsDynamics = function (npsValues) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'area',
      },
      title: {
        style: {
          fontSize: titleFontSize,
        },
        text: t('reports.long_titles.nps_dynamics'),
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      yAxis: [{
        title: {
          text: 'NPS',
        },
      }],
      series: [{
        name: 'TEST_NAME',
        color: '#3FAECA',
        data: npsValues,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y:.0f}', // 0 decimal
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
            textOutline: false,
          },
        },
      }],
      tooltip: {
        pointFormat: t('reports.short_titles.nps_dynamics') + ': <b>{point.y:.0f}</b>',
      },
    })

    removeCopyright()
  }

  self.todayIndexPreview = function (result) {
    // HACK: ugly hack
    if (!hasContainer()) return

    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'column',
        margin: [40, 0, 65, 65],
        height: 400,
      },
      title: {
        style: {
          fontSize: titleFontSize,
        },
        text: t('revenue_comparison_chart'),
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
      },
      xAxis: {
        categories: [t('entry_services'), t('complexes'), t('entry_analyzes'), t('entry_goods')],
      },
      yAxis: {
        labels: {
          format: '{value}',
          enable: false,
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: '',
          style: {сolor: Highcharts.getOptions().colors[1]},
        },
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: false,
        },
      },
      series: [{
        name: result.chart.date_target,
        data: [
          [t('entry_services'), result.chart.services_target],
          [t('complexes'), result.chart.complexes_target],
          [t('entry_analyzes'), result.chart.analyzes_target],
          [t('entry_goods'), result.chart.goods_target],
        ],
      },
      {
        name: result.chart.date_compare,
        color: Highcharts.getOptions().colors[3],
        data: [
          [t('entry_services'), result.chart.services_compare],
          [t('complexes'), result.chart.complexes_compare],
          [t('entry_analyzes'), result.chart.analyzes_compare],
          [t('entry_goods'), result.chart.goods_compare],
        ],
      }],
    })
    removeCopyright()
  }

  self.expenses = function (array) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'column',
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      series: [{
        name: t('sum_price'),
        data: array[0],
      }],
    })

    removeCopyright()
  }

  self.income = function (array) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'column',
      },
      title: {
        style: {
          fontSize: titleFontSize,
        },
        text: t('dynamics_revenue_month'),
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      yAxis: [{
        labels: {
          formatter () {
            return formatter.formatValue(this.value, ['number:currencyWithSign'])
          },
          enable: false,
          style: {
            color: Highcharts.getOptions().colors[2],
          },
        },
        title: {
          text: t('profit'),
          style: {
            color: Highcharts.getOptions().colors[2],
          },
        },
      }, {
        labels: {
          format: '{value}%',
          enable: false,
          style: {
            color: Highcharts.getOptions().colors[3],
          },
        },
        title: {
          text: t('profitability'),
          style: {
            color: Highcharts.getOptions().colors[3],
          },
        },
      }],
      series: [{
        name: t('reports.income'),
        yAxis: 0,
        data: array[0],
      }, {
        name: t('expenses'),
        yAxis: 0,
        data: array[1],
      }, {
        name: t('profit'),
        yAxis: 0,
        type: 'spline',
        color: Highcharts.getOptions().colors[2],
        data: array[2],
      }, {
        name: t('profitability'),
        type: 'spline',
        yAxis: 1,
        tooltip: {
          valueSuffix: ' %',
        },
        color: Highcharts.getOptions().colors[3],
        data: array[3],
      }],
      tooltip: {
        pointFormatter () {
          return formatter.formatValue(this.y, ['number:currencyWithSign'])
        },
      }
    })

    removeCopyright()
  }

  self.department_by_period = function (array) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'area',
      },
      title: {
        style: {
          fontSize: titleFontSize,
        },
        text: t('dynamics_revenue_month'),
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      series: [{
        name: t('reports.income'),
        color: '#9C27B0',
        data: array[0],
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          align: 'right',
          formatter () {
            return formatter.formatValue(this.y, ['number:currencyWithSign'])
          },
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
            textOutline: false,
          },
        },
      }],
      tooltip: {
        pointFormatter () {
          return `${t('reports.income')}: <b>${formatter.formatValue(this.y, ['number:currencyWithSign'])}</b>`
        },
      },
    })

    removeCopyright()
  }

  self.new_clients = function (array) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'column',
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      series: [{
        name: t('reports.new_clients'),
        data: array[0],
      }],
    })

    removeCopyright()
  }

  self.appointmentDynamic = function (result) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'area',
      },
      title: {
        style: {
          fontSize: titleFontSize,
        },
        text: t('reception.appointments'),
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      yAxis: [{
        title: {
          text: result[0].appointment_type_title,
        },
      }],
      series: [{
        name: result[0].appointment_type_title,
        color: '#FF9800',
        data: result[0].visits,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          align: 'right',
          format: '{point.y:.0f}', // 0 decimal
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
            textOutline: false,
          },
        },
      }],
      tooltip: {
        pointFormat: t('reception.appointments') + ': <b>{point.y:.0f}</b>',
      },
    })

    removeCopyright()
  }
  self.newClientsDynamic = function (result) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'column',
      },
      title: {
        style: {
          fontSize: titleFontSize,
        },
        text: t('reports.long_titles.new_clients_dynamic'),
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      yAxis: [{
        title: {
          text: t('reports.short_titles.new_clients_dynamic'),
        },
      }],
      series: [{
        name: t('reports.short_titles.new_clients_dynamic'),
        data: result[0].visits,
      }],
    })

    removeCopyright()
  }
  // self.repeated_visits = function (array) {
  //
  //   var chart = new Highcharts.Chart({
  //     chart: {
  //       renderTo: chart_container,
  //       type: 'column'
  //     },
  //     title: {
  //       text: ''
  //     },
  //     legend: {
  //       enabled: false
  //     },
  //     xAxis: {
  //       categories: []
  //     },
  //     yAxis: [{
  //       title: {
  //         text: 'Повторные визиты'
  //       }
  //     }],
  //     series: [{
  //       name: 'Повторные визиты',
  //       color: Highcharts.getOptions().colors[3],
  //       data: array[0]
  //     }]
  //   });
  //
  //   removeCopyright()
  //
  // };

  self.sources_col = function (array) {
    console.log(array)
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'column',
      },
      title: {
        text: '',
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
      },
      xAxis: {
        categories: [],
      },
      yAxis: [{
        title: {
          text: t('reports.total_amount'),
        },
      }],
      series: [{
        name: t('reports.appointments'),
        data: array[0],
      },
      {
        name: t('reports.visits'),
        data: array[1],
      },
      {
        name: t('reports.clients'),
        data: array[2],
      },
      {
        name: t('reports.calls'),
        data: array[3],
      },
      {
        name: t('reports.new_clients'),
        data: array[4],
      }],
    })

    removeCopyright()
  }

  self.sources_pie = function (array) {
    const byAppointments = array[0]
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container + '-pie',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.1f}</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
            },
          },
        },
      },
      series: [{
        name: t('reports.paid_sum'),
        colorByPoint: true,
        data: byAppointments,
      }],
    })
    removeCopyright()
  }

  self.refuses_col = function (array) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'column',
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      yAxis: [{
        title: {
          text: t('reports.total_amount'),
        },
      }],
      series: [{
        name: t('reports.chart_refuses'),
        data: array[0],
      }],
    })

    removeCopyright()
  }

  self.refuses_pie = function (array) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container + '-pie',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
            },
          },
        },
      },
      series: [{
        name: t('reports.chart_refuses'),
        colorByPoint: true,
        data: array[0],
      }],
    })
    removeCopyright()
  }

  self.departments_col = function (array) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'column',
      },
      title: {
        text: t('sales_dynamics_category'),
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      yAxis: [{
        title: {
          text: t('sum_price'),
        },
      }],
      series: [{
        name: t('sales_by_category'),
        data: array[0],
      }],
    })

    removeCopyright()
  }

  self.departments_pie = function (array) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container + '-pie',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: t('share_categories_in_sales'),
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
            },
          },
        },
      },
      series: [{
        name: 'Brands',
        colorByPoint: true,
        data: array[0],
      }],
    })
    removeCopyright()
  }

  // self.appointments = function (array) {
  //
  //   var chart = new Highcharts.Chart({
  //     chart: {
  //       renderTo: chart_container,
  //       zoomType: 'xy'
  //     },
  //     title: {
  //       text: ''
  //     },
  //     xAxis: [{
  //       categories: [],
  //       crosshair: true
  //     }],
  //     yAxis: [{ // Primary yAxis
  //       labels: {
  //         format: '{value}',
  //         enable: false,
  //         style: {
  //           color: Highcharts.getOptions().colors[1]
  //         }
  //       },
  //       title: {
  //         text: 'Записи на прием',
  //         style: {
  //           color: Highcharts.getOptions().colors[1]
  //         }
  //       }
  //     }, { // Secondary yAxis
  //       title: {
  //         text: 'Неявки',
  //         style: {
  //           color: Highcharts.getOptions().colors[0]
  //         }
  //       },
  //       labels: {
  //         format: '{value} %',
  //         style: {
  //           color: Highcharts.getOptions().colors[0]
  //         }
  //       },
  //       opposite: true
  //     }],
  //     tooltip: {
  //       shared: true
  //     },
  //     legend: {
  //       layout: 'vertical',
  //       align: 'left',
  //       x: 120,
  //       verticalAlign: 'top',
  //       y: 100,
  //       floating: true,
  //       backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
  //     },
  //     series: [{
  //       name: 'Записи на прием, шт',
  //       type: 'column',
  //       color: Highcharts.getOptions().colors[2],
  //       data: array[0],
  //       tooltip: {
  //         valueSuffix: ''
  //       }
  //     }, {
  //       name: 'Неявки, %',
  //       type: 'spline',
  //       color: Highcharts.getOptions().colors[5],
  //       data: array[1],
  //       tooltip: {
  //         valueSuffix: ''
  //       }
  //     }]
  //   });
  //
  //   removeCopyright()
  // };

  self.client_groups = function (array) {
    var chart = new Highcharts.Chart({
      chart: {
        renderTo: chart_container,
        type: 'column',
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: [],
      },
      yAxis: [{
        title: {
          text: t('number_visits'),
        },
      }],
      series: [{
        name: t('number_visits'),
        data: array,
        color: '#FFC107',
      }],
    })

    removeCopyright()
  }

  function removeCopyright () {
    $("text:contains('Highcharts.com')").remove()
  }

  return self
})()
