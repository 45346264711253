Utils.URL = (() => {
  return {
    getSearchParam (param) {
      const params = new URLSearchParams(location.search)

      return params.get(param)
    },

    /**
     * @param {string} key
     * @param {string|number} value
     */
    silentSetQueryParam (key, value) {
      const params = new URLSearchParams(location.search)
      if (value !== undefined && value !== null) {
        params.set(key, value)
        const queryString = params.toString()

        history.replaceState(null, '', `${location.pathname}?${queryString}`)
      }
    },

    silentClearSearchParams () {
      history.replaceState(null, '', `${location.pathname}`)
    },
  }
})()
