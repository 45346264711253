window.AppCache = (() => {
  const appStore = window.store
  const cache = (appStore && appStore.get('AppCache')) || {}

  const reset = (model, data = []) => {
    cache[model] = data.reduce((res, elem) => {
      res[elem.id] = elem

      return res
    }, {})

    appStore.set('AppCache', cache)
  }

  const get = (model, id) => {
    return cache[model][id]
  }

  const populate = (element, attrs) => {
    Object.keys(attrs).forEach((attr) => {
      const key = attr.match(/^(.*)_id$/)[1]
      const storeName = attrs[attr]
      const cachedElement = get(storeName, element[attr])
      element[key] = cachedElement
    })

    return element
  }

  const store = (model, elem) => {
    if (!cache[model]) cache[model] = {}
    cache[model][elem.id] = elem

    appStore.set('AppCache', cache)
  }

  return {
    reset,
    get,
    store,
    populate,
  }
})()
