export const defaultDate = () => {
  return Utils.dateJStoRuby(new Date())
}

export const populateAppointment = (app) => {
  return AppCache.populate(app, {
    user_id: 'user',
    created_by_id: 'user',
    updated_by_id: 'user',
  })
}
