/**
 * Часть списка из https://api.rubyonrails.org/classes/ActiveSupport/TimeZone.html
 */
export const rubyTimezones = Object.freeze({
  'International Date Line West': 'Etc/GMT+12',
  'Dublin': 'Europe/Dublin',
  'Edinburgh': 'Europe/London',
  'Lisbon': 'Europe/Lisbon',
  'London': 'Europe/London',
  'UTC': 'Etc/UTC',
  'Belgrade': 'Europe/Belgrade',
  'Bratislava': 'Europe/Bratislava',
  'Budapest': 'Europe/Budapest',
  'Ljubljana': 'Europe/Ljubljana',
  'Prague': 'Europe/Prague',
  'Sarajevo': 'Europe/Sarajevo',
  'Skopje': 'Europe/Skopje',
  'Warsaw': 'Europe/Warsaw',
  'Zagreb': 'Europe/Zagreb',
  'Brussels': 'Europe/Brussels',
  'Copenhagen': 'Europe/Copenhagen',
  'Madrid': 'Europe/Madrid',
  'Paris': 'Europe/Paris',
  'Amsterdam': 'Europe/Amsterdam',
  'Berlin': 'Europe/Berlin',
  'Bern': 'Europe/Zurich',
  'Zurich': 'Europe/Zurich',
  'Rome': 'Europe/Rome',
  'Stockholm': 'Europe/Stockholm',
  'Vienna': 'Europe/Vienna',
  'Bucharest': 'Europe/Bucharest',
  'Helsinki': 'Europe/Helsinki',
  'Kyiv': 'Europe/Kiev',
  'Riga': 'Europe/Riga',
  'Sofia': 'Europe/Sofia',
  'Tallinn': 'Europe/Tallinn',
  'Vilnius': 'Europe/Vilnius',
  'Athens': 'Europe/Athens',
  'Istanbul': 'Europe/Istanbul',
  'Minsk': 'Europe/Minsk',
  'Jerusalem': 'Asia/Jerusalem',
  'Kaliningrad': 'Europe/Kaliningrad',
  'Moscow': 'Europe/Moscow',
  'St. Petersburg': 'Europe/Moscow',
  'Volgograd': 'Europe/Volgograd',
  'Samara': 'Europe/Samara',
  'Kuwait': 'Asia/Kuwait',
  'Riyadh': 'Asia/Riyadh',
  'Baghdad': 'Asia/Baghdad',
  'Tehran': 'Asia/Tehran',
  'Abu Dhabi': 'Asia/Muscat',
  'Muscat': 'Asia/Muscat',
  'Baku': 'Asia/Baku',
  'Tbilisi': 'Asia/Tbilisi',
  'Yerevan': 'Asia/Yerevan',
  'Kabul': 'Asia/Kabul',
  'Ekaterinburg': 'Asia/Yekaterinburg',
  'Islamabad': 'Asia/Karachi',
  'Karachi': 'Asia/Karachi',
  'Tashkent': 'Asia/Tashkent',
  'Chennai': 'Asia/Kolkata',
  'Kolkata': 'Asia/Kolkata',
  'Mumbai': 'Asia/Kolkata',
  'New Delhi': 'Asia/Kolkata',
  'Kathmandu': 'Asia/Kathmandu',
  'Astana': 'Asia/Dhaka',
  'Dhaka': 'Asia/Dhaka',
  'Sri Jayawardenepura': 'Asia/Colombo',
  'Almaty': 'Asia/Almaty',
  'Novosibirsk': 'Asia/Novosibirsk',
  'Rangoon': 'Asia/Rangoon',
  'Bangkok': 'Asia/Bangkok',
  'Hanoi': 'Asia/Bangkok',
  'Jakarta': 'Asia/Jakarta',
  'Krasnoyarsk': 'Asia/Krasnoyarsk',
  'Beijing': 'Asia/Shanghai',
  'Chongqing': 'Asia/Chongqing',
  'Hong Kong': 'Asia/Hong_Kong',
  'Urumqi': 'Asia/Urumqi',
  'Kuala Lumpur': 'Asia/Kuala_Lumpur',
  'Singapore': 'Asia/Singapore',
  'Taipei': 'Asia/Taipei',
  'Irkutsk': 'Asia/Irkutsk',
  'Ulaanbaatar': 'Asia/Ulaanbaatar',
  'Seoul': 'Asia/Seoul',
  'Osaka': 'Asia/Tokyo',
  'Sapporo': 'Asia/Tokyo',
  'Tokyo': 'Asia/Tokyo',
  'Yakutsk': 'Asia/Yakutsk',
  'Vladivostok': 'Asia/Vladivostok',
  'Magadan': 'Asia/Magadan',
  'Srednekolymsk': 'Asia/Srednekolymsk',
  'Kamchatka': 'Asia/Kamchatka',
})
