import {
  Current,
  Page,
  Placeholder,
  Prev,
  Next,
} from './elements.js'

const SIDE_BLOCK_SIZE = 2
const UPFRONT_SIZE = 4
const SIDE = 7

// generate structure for pagination
const generatePageStructure = ({current, total}) => {
  let leftUpfront = UPFRONT_SIZE
  let rightUpfront = UPFRONT_SIZE
  let result = []

  if (current <= UPFRONT_SIZE) {
    rightUpfront += UPFRONT_SIZE - current + 1
  }
  if (current >= total - UPFRONT_SIZE) {
    leftUpfront += UPFRONT_SIZE - (total - current)
  }

  // left side
  if (current < SIDE + 2) {
    for (let i = 1; i < current; i++) result.push(Page(i))
  } else {
    for (let i = 1; i <= SIDE_BLOCK_SIZE; i++) result.push(Page(i))
    result.push(Placeholder())
    for (let i = current - leftUpfront; i < current; i++) result.push(Page(i))
  }

  result.push(Current(current))

  // right side
  if (current > total - SIDE - 2) {
    for (let i = current + 1; i <= total; i++) result.push(Page(i))
  } else {
    for (let i = current + 1; i <= current + rightUpfront; i++) {
      result.push(Page(i))
    }

    result.push(Placeholder())

    for (let i = total - SIDE_BLOCK_SIZE + 1; i <= total; i++) {
      result.push(Page(i))
    }
  }

  return result
}

const bindEvents = (list, params) => {
  list.on('click', 'a', (e) => {
    e.preventDefault()
    const link = e.currentTarget
    if (link.parentElement.classList.contains('disabled')) return

    const {type, page} = link.dataset
    const {onNav = () => {}, current} = params
    let newPage

    switch (type) {
      case 'page':
        newPage = parseInt(page)
        break
      case 'prev':
        newPage = current - 1
        break
      case 'next':
        newPage = current + 1
        break
      default:
        console.error('wrong pagination element')
    }

    onNav(newPage)
  })
}

// build array of proper elements
const build = (params) => {
  const pages = generatePageStructure(params)
  const prev = Prev({disabled: params.current === 1})
  const next = Next({disabled: params.current === params.total})

  return [prev, ...pages, next]
}

export default (params) => {
  const structure = build(params)
  const content = structure.map((elem) => elem.draw()).join('')
  const list = $('<ul>').addClass('pagination')

  list.html(content)
  bindEvents(list, params)

  return list
}
