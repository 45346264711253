import tippy from 'tippy.js'

export default {
  data () {
    return {
      tooltips: [],
    }
  },
  methods: {
    updateTooltips () {
      this.$nextTick(() => {
        this.tooltips.forEach((tooltip) => tooltip.destroy())

        const config = Object.assign(
          {},
          Utils.tippyConfig,
          {
            content (el) {
              return el.dataset.tooltip
            },
            placement: 'top-start',
          },
        )
        const nodes = this.$el.querySelectorAll('[data-tooltip]:not([data-tooltip=""])')

        this.tooltips = tippy(nodes, config)
      })
    },
  },
}
