<template>
  <modal
    v-if="visibility"
    ref="modalRoot"
    :modal-visibility="visibility"
    modal-class="reminder-modal"
    @close="$emit('close')"
  >
    <span
      slot="header"
      class="modal-header-green"
    >
      <span class="fad fa-tasks" />
      {{ T.reminder }}
    </span>
    <span
      slot="body"
      class="flex-form"
    >
      <div class="form-label">
        <label
          class="string required"
          for="reminder-title"
        >
          <span>
            {{ T.title }}
          </span>
          <abbr :title="T.field_required">
            *
          </abbr>
        </label>
      </div>
      <div class="form-value">
        <div
          class="form-group string element-w100"
        >
          <input
            id="reminder-title"
            v-model="title"
            type="text"
            autocomplete="off"
          >
        </div>
      </div>
      <div class="form-label">
        <label
          class="string required"
          for="reminder-date"
        >
          <span>
            {{ T.date_translate }}
          </span>
          <abbr :title="T.field_required">
            *
          </abbr>
        </label>
      </div>
      <div class="form-value">
        <div class="form-group string required reminder_date">
          <el-date-picker
            v-model="date"
            type="date"
            :format="dateFormat"
          />
        </div>
      </div>
      <div class="form-label">
        <label
          class="string required"
          for="reminder-type"
        >
          <span>
            {{ T.type }}
          </span>
          <abbr :title="T.field_required">
            *
          </abbr>
        </label>
      </div>
      <div class="form-value">
        <items-list
          v-model="type"
          v-bind="reminderTypes"
        />
      </div>
      <div class="form-label">
        <label
          class="string required"
          for="reminder-status"
        >
          <span>
            {{ T.status }}
          </span>
          <abbr :title="T.field_required">
            *
          </abbr>
        </label>
      </div>
      <div class="form-value">
        <items-list
          v-model="status"
          v-bind="reminderStatuses"
        />
      </div>
      <div class="form-label">
        <label
          class="string required"
          for="reminder-client"
        >
          <span>
            {{ T.client }}
          </span>
        </label>
      </div>
      <div class="form-value d-aic">
        <span
          id="reminder-client-actions-modal"
          class="fad fa-bars client-actions"
          :data-client-id="clientId"
        />
        <el-select
          v-model="clientId"
          filterable
          clearable
          remote
          reserve-keyword
          :default-first-option="true"
          :placeholder="T.fullname_or_phone"
          :remote-method="clientFastSearch"
          :no-data-text="noDataText"
          :loading="loading"
          @change="checkForClientId"
        >
          <el-option
            v-for="client in clientsList"
            :key="client.value"
            :label="client.label"
            :value="client.value"
          />
        </el-select>
      </div>
      <div class="form-label">
        <label
          class="string"
          for="reminder-responsible"
        >
          <span>
            {{ T.responsible_users }}
          </span>
        </label>
      </div>
      <div class="form-value">
        <base-select
          v-model="responsibleUsersIds"
          popper-class="user-dropdown-list"
          multiple
          class="user-select base-select__unset-max-width"
        >
          <el-option
            v-for="responsible in responsibles"
            :key="responsible.id"
            :value="responsible.id"
            :label="responsible.fullName"
          >
            <div class="user-dropdown">
              <user-avatar
                :user-id="responsible.id"
                :params="{version: 'thumb16'}"
              />
              <span> {{ responsible.fullName }} </span>
            </div>
          </el-option>
        </base-select>
      </div>
      <div class="form-label">
        <label
          class="string"
          for="reminder-doctor"
        >
          <span>
            {{ T.doctor }}
          </span>
        </label>
      </div>
      <div class="form-value">
        <base-select
          v-model="doctorId"
          popper-class="user-dropdown-list"
          class="user-select base-select__unset-max-width"
          :filterable="true"
          :clearable="doctors.length > 1"
        >
          <el-option
            v-for="doctor in doctors"
            :key="doctor.id"
            :value="doctor.id"
            :label="doctor.fullName"
          >
            <div class="user-dropdown">
              <user-avatar
                :user-id="doctor.id"
                :params="{version: 'thumb16'}"
              />
              <span> {{ doctor.fullName }} </span>
            </div>
          </el-option>
        </base-select>
      </div>
      <div class="form-label">
        <label
          class="string"
          for="reminder-clinic"
        >
          <span>
            {{ T.clinic }}
          </span>
        </label>
      </div>
      <div class="form-value">
        <base-select
          v-model="clinicId"
          popper-class="user-dropdown-list"
          class="user-select base-select__unset-max-width"
          :filterable="true"
          :clearable="clinics.length > 1"
        >
          <el-option
            v-if="clinics.length > 1"
            :value="null"
            :label="T.all_clinics"
          />
          <el-option
            v-for="clinic in clinics"
            :key="clinic.id"
            :value="clinic.id"
            :label="clinic.title"
          >
            <div class="clinic-dropdown">
              <span> {{ clinic.title }} </span>
            </div>
          </el-option>
        </base-select>
      </div>
      <div class="form-label">
        <label
          class="string"
          for="reminder-additional"
        >
          <span>
            {{ T.additional }}
          </span>
        </label>
      </div>
      <div class="form-value">
        <div
          class="form-group string element-w100"
        >
          <el-input
            v-model="additional"
            type="textarea"
            resize="none"
            :autosize="{ minRows: 2}"
          />
        </div>
      </div>
    </span>
    <template
      v-if="action !== 'create'"
      slot="footer-left"
    >
      <button
        class="btn btn-danger btn-sm btn-show-delete modal-delete"
        @click="deleteReminder"
      >
        <span
          class="btn-with-icon_text"
        >
          {{ T.delete }}
        </span>
      </button>
    </template>
    <template
      slot="footer-right"
    >
      <button
        :disabled="!formValid"
        class="btn btn-success btn-with-icon modal-save"
        @click="action === 'create' ? createReminder() : updateReminder()"
      >
        <span
          class="btn-with-icon_icon fad fa-save"
        />
        <span
          class="btn-with-icon_text"
        >
          {{ T.save }}
        </span>
      </button>
      <button
        class="btn btn-warning btn-with-icon modal-save-and-create-appointment"
        :disabled="(!clientId || !formValid)"
        @click="saveAndOpenNewWindow"
      >
        <span
          class="btn-with-icon_icon fad fa-file-import"
        />
        <span
          class="btn-with-icon_text"
        >
          {{ T.save_and_create_appointment }}
        </span>
      </button>
      <button
        class="btn btn-primary btn-with-icon modal-close"
        @click="$emit('close')"
      >
        <span
          class="btn-with-icon_icon fad fa-times"
        />
        <span
          class="btn-with-icon_text"
        >
          {{ T.close }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>

import BaseSelect from '@/vue_components/common/select/base_select/base_select.vue'
import Modal from '@/vue_components/modal.vue'
import ItemsList from '@/vue_components/items_list.vue'
import UserAvatar from '@/vue_components/user_avatar.vue'
import { Intent } from '@/services/intents'

export default {
  name: 'ReminderModal',

  components: {
    BaseSelect,
    Modal,
    ItemsList,
    UserAvatar,
  },

  props: {
    visibility: Boolean,
    reminder: { type: Object, default: null },
    action: { type: String, default: '' },
  },

  data: () => ({
    reminderTypes: Object.freeze({
      items: [
        { value: 'call', title: T.call },
        { value: 'business', title: T.business },
      ],
      minSelectedItems: 1,
      multiSelect: false,
      idItemField: 'value',
      displayedItemField: 'title',
    }),

    reminderStatuses: Object.freeze({
      items: [
        { value: 'pending', title: T.reminder_status_pending },
        { value: 'done', title: T.reminder_status_done },
        { value: 'suspend', title: T.reminder_status_suspend },
        { value: 'fail', title: T.reminder_status_fail },
        { value: 'cancel', title: T.reminder_status_cancel },
      ],
      minSelectedItems: 1,
      multiSelect: false,
      idItemField: 'value',
      displayedItemField: 'title',
    }),

    // modal attributes
    title: '',
    date: '',
    type: [],
    status: [],
    clientId: null,
    responsibleUsersIds: [],
    doctorId: null,
    clinicId: null,
    additional: '',
    createdById: gon.application.current_user.id,
    updatedById: null,
    reminderId: null,

    // collections for selectors
    serviceUsers: [],
    clientsList: [],
    responsibles: gon.specific.responsible_users,
    doctors: gon.specific.med_users,
    clinics: gon.specific.clinics,

    dateFormat: '',
    reminderTypesValues: gon.specific.reminder_types,
    reminderStatusesValues: gon.specific.reminder_statuses,
    noDataText: T.patient_not_found,
    loading: false,
  }),

  computed: {
    formValid () {
      return this.title.length && this.title.trim().length > 0 && this.date.toString().length
    },
  },

  watch: {
    reminder () {
      this.clientId = this.reminder.clientId
      this.getRemindersClient()
      this.title = this.reminder.title
      this.date = new Date(this.reminder.date)
      this.type = [this.reminderTypesValues[this.reminder.reminderType]]
      this.status = [this.reminderStatusesValues[this.reminder.status]]
      this.responsibleUsersIds = this.reminder.responsibleIds
      this.doctorId = this.reminder.doctorId
      this.clinicId = this.reminder.clinicId
      this.additional = this.reminder.note
      this.createdById = this.reminder.createdById
      this.updatedById = this.reminder.updatedById
      this.reminderId = this.reminder.id
    },
  },

  created () {
    this.defineDateFormat()
  },

  methods: {
    createReminder () {
      const self = this

      $.ajax({
        type: 'POST',
        url: Routes.reminders_path(),
        data: {
          reminder: {
            date: self.date,
            title: self.title,
            reminder_type: self.type[0],
            status: self.status[0],
            note: self.additional,
            client_id: self.clientId,
            doctor_id: self.doctorId,
            responsible_ids: self.responsibleUsersIds,
          },
        },
        success () {
          self.$emit('updated')
          Services
            .ui
            .notificator()
            .success(T.system_created_reminder)
        },
        error () {
          Services
            .ui
            .notificator()
            .error(T.abstract_error_message, T.error, 0)
        },
      })
    },

    updateReminder () {
      const self = this

      $.ajax({
        type: 'PATCH',
        url: Routes.reminder_path(self.reminderId),
        data: {
          reminder: {
            date: self.date,
            title: self.title,
            reminder_type: self.type[0],
            status: self.status[0],
            note: self.additional,
            client_id: self.clientId,
            doctor_id: self.doctorId,
            responsible_ids: self.responsibleUsersIds,
          },
        },
        success (test) {
          self.$emit('updated')
          Services
            .ui
            .notificator()
            .success(T.notifications.types.reminder.updated)
        },
        error () {
          Services
            .ui
            .notificator()
            .error(T.abstract_error_message, T.error, 0)
        },
      })
    },

    deleteReminder () {
      const self = this

      $.ajax({
        type: 'DELETE',
        url: Routes.reminder_path(self.reminderId),
        success () {
          self.$emit('updated')
          Services
            .ui
            .notificator()
            .success(T.notifications.types.reminder.deleted)
        },
        error () {
          Services
            .ui
            .notificator()
            .error(T.abstract_error_message, T.error, 0)
        },
      })
    },

    clientFastSearch (query) {
      if (query.length < 3) {
        this.clientsList = []
        const symbolCount = 3 - query.length
        const queryText = T.please_enter_least + symbolCount + T.symbols
        this.noDataText = symbolCount === 2 ? queryText : queryText.slice(0, -1)

        return
      }

      this.noDataText = T.patient_not_found
      const self = this
      self.loading = true
      $.ajax({
        type: 'POST',
        url: Routes.fast_search_clients_path(),
        data: { title: query },
        success (clients) {
          self.clientsListHandler(clients)
        },
        complete () {
          self.loading = false
        },
      })
    },

    getRemindersClient () {
      const self = this

      $.ajax({
        type: 'GET',
        data: { id: self.clientId },
        url: Routes.fast_search_selected_clients_path(),
        success (client) {
          self.clientsListHandler([client])
        },
      })
    },

    defineDateFormat () {
      if (gon.localization.date_format.charAt(0) === 'd') {
        this.dateFormat = 'dd.MM.yyyy'
      } else {
        this.dateFormat = 'MM.dd.yyyy'
      }
    },

    clientsListHandler (clients) {
      this.clientsList = clients.map((client) => {
        return {
          value: client.id,
          label: `${client.fullname}, д/р: ${client.formatted_birthdate}, тел. ${client.formatted_phone || client.masked_phone || ''}`,
        }
      })
    },

    checkForClientId () {
      if (this.clientId.length === 0) this.clientsList = []
    },

    saveAndOpenNewWindow () {
      const newWindow = window.open(Routes.doctor_schedules_path(), '_blank')
      Intent.setIntent(
        newWindow,
        Intent.SCHEDULE_APPOINTMENT_CREATE,
        { clientId: this.clientId }
      )

      if (this.action === 'create') {
        this.createReminder()
      } else {
        this.updateReminder()
      }
    },
  },

}
</script>
