import paginationGenerator from '../../plugins/paginationGenerator'
import { Intent } from '@/services/intents'

const DEFAULT_PER_PAGE = 50
const DEFAULT_PAGE = 1

class Notification {
  constructor () {
    this._store = {}
  }

  requestNotifications (page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE) {
    const promise = new Promise((resolve, reject) => {
      $.ajax({
        type: 'get',
        url: Routes.notifications_path(),
        data: {
          page,
          per_page: perPage,
        },
        success: ({ data: notifications, page, per_page: perPage, total_pages: totalPages }) => {
          $.extend(this._store, { notifications, page, totalPages, perPage })
          this._renderNotifications()
          this._updateDismissBtn()
          resolve()
        },
        error (err) {
          console.error(err)
          reject(err)
        },
      })
    })

    promise.then(this._count.bind(this))

    return promise
  }

  init (callback) {
    this.modal = $('#notifications_modal')
    this._store.notifications = []
    this.reminderForm = undefined
    this.notificationsTemplate = Handlebars.compile($('#handlebars_notifications_tmpl').html())

    return this.requestNotifications()
      .then(callback)
  }

  _renderNotifications () {
    $('#notifications_container').html(this.notificationsTemplate(this._store))

    if (this._store.totalPages > 1) {
      const pagination = paginationGenerator({
        current: this._store.page,
        total: this._store.totalPages,
        onNav: (pageNumber) => {
          this.requestNotifications(pageNumber)
        },
      })
      $('#notifications_modal .modal-footer-left').empty()
        .append(pagination)
    }
  }

  _count () {
    const $notificationBadge = $('.notification-badge')
    const counter = this._store.notifications.length

    $notificationBadge.text(counter)

    if (counter === 0) {
      $notificationBadge.addClass('notifications-empty')
      $notificationBadge.removeClass('notifications-some')
    } else {
      $notificationBadge.addClass('notifications-some')
      $notificationBadge.removeClass('notifications-empty')
    }
  }

  dismiss (notificationId) {
    $.ajax(Routes.dismiss_notification_path(notificationId), {
      success: () => {
        $('#notifications_container').find('[data-id=' + notificationId + ']').fadeOut()
        this._updateDismissBtn()
      },
      error (err) {
        console.error(err)
      },
    })
  }

  dismissAll () {
    $.ajax(Routes.dismiss_all_notifications_path(), {
      success: () => {
        $('#notifications_container').find('.notification-row').fadeOut()
        $('.notification-badge').text(0)
        this._updateDismissBtn()
      },
      error (err) {
        console.error(err)
      },
    })
  }

  update (notification, callback) {
    if (notification._deleted) {
      for (let i = 0; i < this._store.notifications.length; i++) {
        if (notification.id === this._store.notifications[i].id) {
          this._store.notifications.splice(i, 1)
          Notify.close(notification.id)
          break
        }
      }
    } else {
      if (notification.body) {
        this._store.notifications.unshift(notification)
      }

      const notify = notification.bootstrap_notify
      if (notify) {
        notify.custom_settings.id = notification.id
        Notify.create(notify)
      }
    }
    this._renderNotifications()
    callback()
    this._updateDismissBtn()
    this._count()
  }

  generateReminderForm (params) {
    if (!params) params = {}
    if (!params.onSubmitAndCreateAppointment) {
      params.onSubmitAndCreateAppointment = (reminder) => {
        const newWindow = window.open(Routes.doctor_schedules_path(), '_blank')
        Intent.setIntent(
          newWindow,
          Intent.SCHEDULE_APPOINTMENT_CREATE,
          { clientId: reminder.client_id }
        )
      }
    }

    this.reminderForm = FormFactory.build('reminder', params)

    return this.reminderForm
  }

  openReminderForm (reminderID) {
    $.ajax(Routes.reminder_path(reminderID, { from_notification: true }), {
      success: (reminder) => {
        if (!this.reminderForm) {
          this.generateReminderForm()
        }

        this.reminderForm.editItem(reminder)
      },
      error (err) {
        console.error(err)
      },
    })
  }

  getReminderForm () {
    return this.reminderForm
  }

  openModal () {
    this.modal.modal('show')
  }

  _updateDismissBtn () {
    const notifications = this._store.notifications || []

    if (notifications.length === 0) {
      this.modal.find('.mark-all-as-read').attr('disabled', 'disabled')
      this.modal.modal('hide')
    } else {
      this.modal.find('.mark-all-as-read').removeAttr('disabled')
    }
  }

  getCount () {
    return this._store.notifications.length
  }
}

window.Notification = new Notification()
