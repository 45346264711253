/* eslint-disable camelcase */
// Module for creating reports
// Report.generate({ url: '/reports/create_income', reportName: 'createIncome'})
/**
 * @typedef {{date: string, value: string}} ReportEntry
 */

/**
 * @typedef {{[string]: ReportEntry[]}} ReportData
 * @property {object} [totals]
 */

/**
 * @typedef {ReportData & {
 *  expenses: ReportEntry[],
 *  income: ReportEntry[],
 *  profit: ReportEntry[],
 *  profitability: ReportEntry[],
 * }} IncomeReportData
 *
 */

import menu from './report.menu.js'
import listHandler from './report.list_handler.js'
import paginationGenerator from '../../plugins/paginationGenerator'

const DEFAULT_PER_PAGE = 1000
const DEFAULT_PAGE = 1
const PADDING_BOTTOM_TABLE = 30

/**
 * Парсер для денег, заменяет разделитель в числе на '.'
 */
$.tablesorter.addParser({
  id: 'moneySeparatorFixer',
  is () { return false },
  format (s) {
    return parseFloat(s
      .toString()
      .replaceAll(' ', '')
      .replace(',', '.')
    ) || 0
  },
  parsed: true,
  type: 'numeric',
})

window.Report = (function () {
  let generate
  let queryParams = null
  let reportsList = {}
  const jQM = {}
  const optMap = {}
  const init = function (options = {}) {
    queryParams = null
    const formElems = $('#report_form').find(':input')
    formElems.on('change', storeFormParams)
    menu.init(options)
    listHandler.init(options)
  }

  const restore = function () {
    const params = $.deparam(window.location.search.slice(1))
    let restoring = false
    if (params.report_query) {
      const opts = params.report_query
      for (const id in opts) {
        if (!opts.hasOwnProperty(id)) continue
        const elem = $('#' + id)
        if (elem.length) {
          elem.val(opts[id]).change()
          restoring = true
        }
      }

      if (restoring) $('#report_submit').click()
    }
  }

  generate = function (options) {
    optMap.url = options.url
    optMap.disableTooltipThead = options.disableTooltipThead
    optMap.reportName = options.reportName
    optMap.defaults = options.defaults || {}

    if (options.goReport) {
      reportsList[optMap.reportName]()
    } else {
      jQM.reportTableTemplate = $('#report_table_template')
      jQM.sbmButton = $('#report_submit')
      jQM.resetButton = $('#report_reset')
      jQM.reportForm = $('#report_form')
      jQM.paginationContainer = $('.report_pagination_container')
      jQM.reportTable = $('#report_table')
      jQM.reportTableContainer = $('#report_table_container')
      jQM.reportContainer = $('.report')
      jQM.reportChartContainer = $('#chart_container')
      jQM.reportChartPieContainer = $('#chart_container-pie')
      jQM.reportSubtitle = $('.report-subtitle')
      jQM.dateStart = $('#date_start')
      jQM.dateEnd = $('#date_end')
      jQM.noDataMsg = `<table><tr><td><span>${t('errors.error_occurred_while_searching')}</span></td></tr></table>`

      if (!jQM.reportForm.hasErrors()) {
        _getData()
      }

      if (!optMap.defaults.hasOwnProperty('page')) {
        optMap.defaults.page = DEFAULT_PAGE
      }
    }
  }

  function storeFormParams () {
    const formElems = $('#report_form').find(':input')
    const params = {}

    formElems.each(function (i, e) {
      const elem = $(e)
      const name = elem.attr('name')
      if (/report/.test(name) && elem.val()) {
        if (elem.is(':checkbox')) {
          params[elem.attr('id')] = elem[0].checked
        } else {
          params[elem.attr('id')] = elem.val()
        }
      }
    })

    queryParams = params
  }

  reportsList = {
    createIndexPreview (result) {
      _appendResult(result, {
        reportTables: ['report_compare', 'report_common_indicators'],
      })
      Report.drawChart.todayIndexPreview(result)
      _addTooltipsToTableHeader()
      _resize()
      $(window).resize(() => _resize())

      function _resize () {
        $('.index_report_right_container')
          .height($('.main-container').height() - 80)
      }

      Utils.updateTooltips(document.querySelector('.index_report_right_container'))
    },

    createDayReport (result) {
      _appendSubtitle(result)
      const templateFinance = Handlebars.compile($('#report_finance_table_template').html())
      const templateAppointments = Handlebars.compile($('#report_appointments_table_template').html())
      const templateDoctors = Handlebars.compile($('#report_doctors_table_template').html())
      const templateAdministrators = Handlebars.compile($('#report_administrators_table_template').html())

      $('#report_finance_container').empty().append(templateFinance(result))
      $('#report_appointments_container').empty().append(templateAppointments(result))
      $('#report_doctors_container').empty().append(templateDoctors(result))
      $('#report_administrators_container').empty().append(templateAdministrators(result))

      _resizeTableContainer()
      $(window).resize(() => _resizeTableContainer())
      _tablesorter({
        headers: {
          3: { sorter: 'moneySeparatorFixer' }, // по хорошему надо как-то сделать проверку на url hash: #doctors,
          4: { sorter: 'moneySeparatorFixer' }, // но в обоих случаях столбцы numeric и сортировка отлично работает
          6: { sorter: 'moneySeparatorFixer' }, // эти столбцы для вкладки
          7: { sorter: 'moneySeparatorFixer' }, // "Администраторы"
        },
      })
      _addTooltipsToTableHeader()
    },

    createOrdersPerPeriod (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          2: { sorter: 'eudate' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createPaymentsPerPeriod (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          4: { sorter: 'eudate' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createExpenses (result) {
    },

    /**
     *
     * @param {{data: IncomeReportData}} result
     * @return {*}
     */
    createIncome (result) {
      _appendSubtitle(result)
      _appendResult(result)

      const chartData = []
      const parseRes = (res) => [res.date, parseFloat(res.value)]
      chartData.push(result.data.income.map(parseRes))
      chartData.push(result.data.expenses.map(parseRes))
      chartData.push(result.data.profit.map(parseRes))
      chartData.push(result.data.profitability.map(parseRes))
      Report.drawChart.income(chartData)

      return result
    },

    createHeadIncome (result) {
      return result
    },

    createSalary (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter()
    },

    createCalls (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter()
    },

    createRefuseResults (result) {
      _appendSubtitle(result)
      _appendResult(result)

      const chartData = []
      const parseRes = function (res) {
        return [res.title, parseFloat(res.count)]
      }
      chartData.push(result.data.refuse_results.map(parseRes))
      Report.drawChart.refuses_col(chartData)
      Report.drawChart.refuses_pie(chartData)
      _addTooltipsToTableHeader()
    },

    createCallEffect (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          7: { sorter: 'digit' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createDoctorAppointments (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          0: { sorter: 'eudate' },
        },
      })
    },

    createSources (result) {
      _appendSubtitle(result)
      _appendResult(result)
      const chartDataList = [
        'appointments',
        'visits',
        'clients',
        'calls',
        'new_clients',
        'paid_sum',
      ]
      const chartData = []
      const parseRes = function (res) {
        return [res.title, parseFloat(res[this])]
      }

      chartDataList.forEach(function (dataTitle) {
        chartData.push(result.data.attraction_sources.map(parseRes, dataTitle))
      })

      Report.drawChart.sources_col(chartData)
      Report.drawChart.sources_pie(chartData)
      _tablesorter({
        headers: {
          6: { sorter: 'moneySeparatorFixer' },
          7: { sorter: 'moneySeparatorFixer' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createAppointmentDynamic (result) {
      _appendSubtitle(result)
      _appendResult(result)

      const chartData = []
      const parseRes = function (res) {
        return [res.date, parseFloat(res.value)]
      }
      chartData.push({
        visits: result.data.visits.map(parseRes),
        appointment_type_title: result.data.appointment_type_title,
      })
      Report.drawChart.appointmentDynamic(chartData)
      _addTooltipsToTableHeader()
    },
    createNewClientsDynamic (result) {
      _appendSubtitle(result)
      _appendResult(result)

      const chartData = []
      const parseRes = function (res) {
        return [res.date, parseFloat(res.value)]
      }
      chartData.push({ visits: result.data.visits.map(parseRes) })
      Report.drawChart.newClientsDynamic(chartData)
      _addTooltipsToTableHeader()
    },

    createUsersIncome (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter()
      _addTooltipsToTableHeader()
    },

    createBusyness (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _addTooltipsToTableHeader()
    },

    createProductivity (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          1: { sorter: 'moneySeparatorFixer' },
          4: { sorter: 'moneySeparatorFixer' },
          5: { sorter: 'moneySeparatorFixer' },
          6: { sorter: 'digit' },
          7: { sorter: 'digit' },
          9: { sorter: 'moneySeparatorFixer' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createInsuranceCompanies () {
      _addHorizontalScroll()
      _addTooltipsToTableHeader()
    },

    createPaymentTypes (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter()
      _addTooltipsToTableHeader()
    },

    createClientGroups (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter()
      Report.drawChart.client_groups(result.data)
      _addTooltipsToTableHeader()
    },

    createClientsDebt (result) {
      _appendSubtitle(result)
      if (result.templateId) {
        _appendResult(result, {
          reportTable: result.templateId,
        })
      } else {
        _appendResult(result)
      }
      const $footer = $('.report_table tfoot')
      if (result.data.companies && result.data.companies.length < 2) {
        $footer.hide()
      } else {
        $footer.show()
      }
      _tablesorter({
        headers: {
          4: { sorter: 'moneySeparatorFixer' },
          5: { sorter: 'moneySeparatorFixer' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createDentalLabs (result) {
      _appendSubtitle(result)
      _templateCompile(result.templateId)
      _appendResult(result, { preRenderedTemplate: true })
      _tablesorter({
        headers: {
          '.date': { sorter: 'eudate' },
          '.index-sum': { sorter: 'moneySeparatorFixer' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createAdministrators (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          8: { sorter: 'moneySeparatorFixer' },
          9: { sorter: 'moneySeparatorFixer' },
          10: { sorter: 'moneySeparatorFixer' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createDepartments (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          1: { sorter: 'moneySeparatorFixer' },
        },
      })

      const chartData = []
      const parseRes = function (res) {
        return [res.title, parseFloat(res.sum)]
      }
      chartData.push(result.data.departments.map(parseRes))
      Report.drawChart.departments_col(chartData)
      Report.drawChart.departments_pie(chartData)
      _addTooltipsToTableHeader()
    },

    createDiseases (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter()
      _addTooltipsToTableHeader()
    },

    createCancellations (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter()
    },

    createLaboratoryRegisters (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          '.sum': { sorter: 'moneySeparatorFixer' },
          '.date': { sorter: 'eudate' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createPrintScheduler (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _addTooltipsToTableHeader()
    },

    createPrintAppointments (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _addTooltipsToTableHeader()
    },

    createCustomerLoyalty (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter()
      _addTooltipsToTableHeader()
    },

    createManipulations (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          3: { sorter: 'eudate' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createDepartmentByPeriod (result) {
      _appendSubtitle(result)
      _appendResult(result)

      const chartData = []
      const parseRes = function (res) {
        return [res.date, parseFloat(res.value)]
      }
      chartData.push(result.data.map(parseRes))
      Report.drawChart.department_by_period(chartData)
      _addTooltipsToTableHeader()
    },

    createEntriesByPeriod (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter()
      _addTooltipsToTableHeader()
    },

    createClientDuplicates (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          '.date': { sorter: 'eudate' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createOfficesWorkload (result) {
      _appendSubtitle(result)
      _appendResult(result)
      _tablesorter({
        headers: {
          1: { sorter: 'digit' },
        },
      })
      _addTooltipsToTableHeader()
    },

    createNpsDynamics (result) {
      _appendSubtitle(result)
      _appendResult(result)
      Report.drawChart.npsDynamics(result)
      _addTooltipsToTableHeader()
    },

    createAdmissionAssessmentDynamics (result) {
      _appendSubtitle(result)
      _appendResult(result)
      Report.drawChart.admissionAssessmentDynamics(result)
      _addTooltipsToTableHeader()
    },
  }

  /// ///////////////// Local Methods ////////////////////////////////////

  function _getData (paginationParams = {}) {
    _getDataInit()

    const {
      page = DEFAULT_PAGE,
      per_page = DEFAULT_PER_PAGE,
    } = paginationParams
    jQM.params.push({ name: 'report[page]', value: page }, { name: 'report[per_page]', value: per_page })

    $.ajax({
      type: 'POST',
      url: optMap.url,
      data: jQM.params,
      success (result) {
        reportsList[optMap.reportName](result)
        Utils.updateTooltips(document.querySelector('.report'))
      },
      error (err) {
        console.error('report_error', err)
        jQM.reportTable.empty().append(jQM.noDataMsg)
      },
      complete () {
        jQM.sbmButton.removeAttr('disabled')
        jQM.reportContainer.loadSpin('stop')
      },
    })
  }

  function _getDataInit () {
    jQM.params = jQM.reportForm.serializeArray()
    jQM.sbmButton.attr('disabled', 'disabled')
    jQM.reportContainer.loadSpin('start')
    jQM.reportSubtitle.empty()
    jQM.reportChartContainer.empty()
    jQM.reportChartPieContainer.empty()
  }

  function _appendResult (result, options = {}, paginationParams = {}) {
    // for several tables case
    Object.assign(result, paginationParams)
    if (options.reportTables) {
      for (const tableName of options.reportTables) {
        const template = $(`#${tableName}_template`).html()
        const compiledTemplate = Handlebars.compile(template)

        $(`#${tableName}_container`)
          .empty()
          .append(compiledTemplate(result))
      }

      // for changing single tables case
    } else if (options.reportTable) {
      const template = $(options.reportTable).html()
      const compiledTemplate = Handlebars.compile(template)
      jQM.reportTableContainer
        .empty()
        .append(compiledTemplate(result))

      // for single table case
    } else {
      if (options.preRenderedTemplate) {
        const compiledTemplate = jQM.template
        jQM.reportTableContainer
          .empty()
          .append(compiledTemplate(result))
      } else {
        const template = $(jQM.reportTableTemplate).html()
        const compiledTemplate = Handlebars.compile(template)
        jQM.reportTableContainer
          .empty()
          .append(compiledTemplate(result))
      }
    }

    if (result.page !== undefined) {
      jQM.paginationContainer
        .empty()
        .append(paginationGenerator({
          current: result.page,
          total: result.total_pages,
          onNav (pageNumber) {
            _getData({ page: pageNumber })
          },
        })
        )
      $('.report_pagination_container').show()
    } else {
      $('.report_pagination_container').hide()
    }
    _resizeTableContainer()
    $(window).resize(() => _resizeTableContainer())
    $('.chart-item').show()
    Services.telephony.reset()
  }

  function _getSubtitles () {
    let subtitles = ''
    jQM.reportForm.find('input#period:enabled').each(function () { subtitles = subtitles + $(this).val() + '. ' })
    jQM.reportForm.find('input#single_date:enabled').each(function () { subtitles = subtitles + $(this).val() + '. ' })
    jQM.reportForm.find('select:enabled option:selected').each(function () {
      subtitles = subtitles + $(this).text() + '. '
    })

    jQM.reportForm.find('select:enabled.select2-hidden-accessible').each(function () {
      if (!$(this).val() && $(this).data('select2').selection.placeholder) {
        subtitles = subtitles + $(this).data('select2').selection.placeholder.text + '. '
      }
    })

    jQM.reportForm.find('.checkbox:checked').each(function () {
      subtitles += $(this).parents('label').text() + '. '
    })

    return subtitles
  }

  function _appendSubtitle () {
    jQM.reportSubtitle.text(_getSubtitles())
  }

  function _addHorizontalScroll () {
    const container = $('.report_table_container')
    const header = container.find('thead')[0]
    const footer = container.find('tfoot')[0]
    const tbody = container.find('tbody')[0]
    const tableStaticElements = [header, footer]

    // TODO: possibly needs some performance improvments
    container.find('tbody').on('scroll', () => {
      const marginLeft = `-${tbody.scrollLeft}px`

      tableStaticElements.forEach((elem) => {
        elem.style.marginLeft = marginLeft
      })
    })
  }

  /// ///////////////////// supporting methods //////////////////////////////

  function _tablesorter (options = {}) {
    $('.report_table')
      .tablesorter({
        widgets: ['staticRow'],
        dateFormat: gon.application.date_format,
        headers: options.headers,
        textSorter (a, b) {
          return a.localeCompare(b)
        },
      })
  }

  function _resizeTableContainer () {
    /**
     * table height calculate of: main container sub
     *  heights: panel height, report subtitle, tab panel and padding bottom
     */
    const tableContainerHeight = $('.main-container').height() -
      $('.report_panel_primary .panel-heading').height() -
      $('.report-subtitle').height() -
      $('.inner_tabpanel').height() - PADDING_BOTTOM_TABLE

    if (!$('.chart-container').length || !$('.report-chart-container').length) {
      $('table.report_table:not(.initial_size)').height(tableContainerHeight)
    }
  }

  /**
   * Запускать строго после отрабатывания jquery.tablesorter, так как
   * он перезаписывает html заголовков таблиц и у тултипов теряется ссылка на элемент.
   * Тултипы после этого не работают, а память течёт.
   * @private
   */
  function _addTooltipsToTableHeader () {
    if (!optMap.disableTooltipThead) {
      $('.report_table th').not(':has(.asterisk)').each(
        function () {
          $(this)
            .addClass('tooltip-bottom')
            .attr('title', $(this).text())
        })

      $('.report_table tfoot td').not(':has(.asterisk)').not(':first-child').each(
        function () {
          const $cell = $(this)
          let title = $cell.attr('title')
          if (!title) {
            const $titledChild = $cell.find('[title]')
            if ($titledChild.length) {
              title = $titledChild.attr('title')
              if (title) {
                $titledChild.addClass('tooltip-bottom')
              }
            }
          }
          if (!title) {
            $cell
              .attr('title', $cell.text())
              .addClass('tooltip-bottom')
          }
        })
    }
    Utils.epicUpdateTooltipsContainer({
      selector: '.report-table-container',
      extendDefaultConfig: {
        placement: 'top-start',
      },
    })
  }

  function _templateCompile (templateId = jQM.reportTableTemplate) {
    jQM.template = Handlebars.compile($(templateId).html())
  }

  /// ////////////////// Local Methods END ////////////////////////////

  /// ///////////////// Public Methods START ////////////////////////////////////

  /**
   * @param {JQuery} $filter
   * @return {JQuery}
   */
  function getFilterContainer ($filter) {
    return $filter.parents('.filter-container')
  }

  /**
   * Returns filter container, NOT THE FILTER INPUT
   *
   * @see {getFilterContainer}
   * @param {JQuery} $filter
   * @return {JQuery}
   */
  function showFilter ($filter) {
    $filter.prop('disabled', false)

    return getFilterContainer($filter).show()
  }

  /**
   * Returns filter container, NOT THE FILTER INPUT
   *
   * @see {getFilterContainer}
   * @param {JQuery} $filter
   * @return {JQuery}
   */
  function hideFilter ($filter) {
    $filter.prop('disabled', true)

    return getFilterContainer($filter).hide()
  }

  /// ///////////////// Public Methods END ////////////////////////////////////
  window._addTooltipsToTableHeader = _addTooltipsToTableHeader

  return {
    init,
    restore,
    generate,
    getFilterContainer,
    showFilter,
    hideFilter,
  }
})()
