import {
  populateAppointment,
} from '@/plugins/schedule/scheduleCommon/utils.js'
import { Intent } from '@/services/intents'

window.LastAppointments = (function () {
  let $modal
  let $checkBoxes

  let appointmentStore
  let currentClient
  let appointmentForm
  let initialized

  const init = (params) => {
    if (initialized || !gon.application.current_user) return

    params = params || {}
    $modal = $('#modal_appointments_show')
    appointmentStore = {}
    appointmentForm = params.appointmentForm

    $modal.viewInModal({
      template: $('#handlebars_appointments_show').html(),
    })

    _bindEvents()
    initialized = true
  }

  const clear = () => {
    initialized = false
  }

  const show = (clientId) => {
    const promise = new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: Routes.fetch_appointments_appointments_path(),
        data: {
          client_id: clientId,
        },
        success (data) {
          appointmentStore = {}
          currentClient = data.client

          data.appointments.forEach((app) => {
            appointmentStore[app.id] = populateAppointment(app)
          })

          resolve(data)
        },
        error (err) {
          reject(err)
          console.error(err)
        },
      })
    })

    $modal.viewInModal('show', promise)

    promise.then(() => {
      Services.telephony.reset()
      $checkBoxes = $modal.find('.appointment-checkbox')
      $checkBoxes.each((i, e) => {
        const $checkbox = $(e)
        const id = $checkbox.data('id')
        const app = appointmentStore[id]

        if (app.order) {
          $checkbox.attr('disabled', true)
        }
      })
    })
  }

  const _bindEvents = () => {
    const pageName = gon.page.controller

    _bindGeneralEvents()
    pageName === 'doctor_schedules' ? _fromDoctorSchedules() : _fromOutside()
  }

  const _fromDoctorSchedules = () => {
    $modal.on('click', '.schedule-grid-appointment-move', function () {
      const id = $(this).data('id')
      const app = appointmentStore[id]

      Services.SGAM.invokePublicVisibleScheduleMethod(
        Services.SGAM.public.common.option,
        {mode: $.scheduleGrid.MODE_MOVE})

      Services.SGAM.invokePublicVisibleScheduleMethod(
        Services.SGAM.public.common.setActiveAppointment,
        app)

      $modal.modal('hide')
    })

    $modal.on('click', '.add_appointment', () => {
      Services.SGAM.clientToInsert = currentClient

      Services.SGAM.invokePublicVisibleScheduleMethod(
        Services.SGAM.public.common.option,
        {mode: $.scheduleGrid.MODE_CLIENT_INSERT})

      $modal.modal('hide')
    })

    $modal.on('click', '.schedule-grid-appointment-edit', function () {
      const id = $(this).data('id')
      const app = appointmentStore[id]

      appointmentForm.editItem(app)
      $modal.modal('hide')
    })
  }

  const _fromOutside = () => {
    $modal.on('click', '.add_appointment', () => {
      const newWindow = window.open(Routes.doctor_schedules_path(), '_blank')
      Intent.setIntent(
        newWindow,
        Intent.SCHEDULE_APPOINTMENT_CREATE,
        { clientId: currentClient.id },
      )
    })

    $modal.on('click', '.schedule-grid-appointment-move', function () {
      const id = $(this).data('id')
      const app = appointmentStore[id]
      const url = Routes.doctor_schedules_path()
      const newWindow = window.open(url, '_blank')
      Intent.setIntent(
        newWindow,
        Intent.SCHEDULE_APPOINTMENT_MOVE,
        { appointment: app },
      )
    })

    $modal.on('click', '.schedule-grid-appointment-edit', function () {
      const id = $(this).data('id')
      const app = appointmentStore[id]
      const url = Routes.doctor_schedules_path()
      const newWindow = window.open(url, '_blank')
      Intent.setIntent(
        newWindow,
        Intent.SCHEDULE_APPOINTMENT_EDIT,
        { appointment: app },
      )
    })
  }

  const _bindGeneralEvents = function () {
    $modal.on('click', '.schedule-grid-appointment-cancel', function () {
      const id = $(this).data('id')
      const app = appointmentStore[id]
      const msg = t('reception.do_you_really_want_to_cancel_appointment')

      bootbox.confirmYN(msg, (res) => {
        if (!res) return

        $modal.viewInModal('freeze')

        $.ajax({
          url: Routes.appointment_path(app.id),
          method: 'PATCH',
          data: {
            appointment: {
              status: 'canceled',
            },
          },
          success (app) {
            show(app.client_id)
          },
          error (err) {
            console.error(err)

            $modal.viewInModal('unfreeze')
          },
        })
      })
    })

    $modal.on('click', '.appointment-to-order', function () {
      const id = $(this).data('id')
      const url = Routes.order_path(appointmentStore[id].order_id)

      Turbolinks.visit(url)
    })

    $modal.on('click', '.appointment-create-order', function () {
      const id = $(this).data('id')
      const app = appointmentStore[id]
      const url = Routes.new_order_path({
        client_id: app.client_id,
        appointment_ids: app.id,
        referral_id: app.referral_id,
      })

      Turbolinks.visit(url)
    })

    $modal.on('click', '.appointment-checkbox-all', function () {
      const main = $(this)
      const value = main[0].checked

      $modal.find('.appointment-checkbox').each((i, e) => {
        const checkbox = $(e)

        if (checkbox.is(':enabled')) {
          e.checked = value
          checkbox.change()
        }
      })
    })

    $modal.on('change', '.appointment-checkbox', () => {
      $('.modal-multi-order').prop('disabled', $checkBoxes.filter(':checked').length < 1)
    })

    $modal.on('click', '.modal-multi-order', () => {
      const appointmentIds = []
      $checkBoxes.filter(':checked').each((i, e) => {
        appointmentIds.unshift($(e).data('id'))
      })

      const url = Routes.new_order_path({
        client_id: appointmentStore[appointmentIds[0]].client_id,
        appointment_ids: appointmentIds,
      })
      Turbolinks.visit(url)
    })
  }

  const editAppointment = (app) => {
    appointmentForm.editItem(app)
  }

  return {
    show,
    init,
    clear,
    editAppointment,
  }
})()
