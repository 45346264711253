export default {
  init (options) {
    _btnResetHandler(options)
    _daterangepickerInit(options)

    const fieldHandlers = new FieldHandlers()
    fieldHandlers.entry_type_ajax()
    fieldHandlers.entry_types_ajax()
    fieldHandlers.entry_type_categories()
    fieldHandlers.selection()
    fieldHandlers.select2hideDropdown()
    fieldHandlers.fixSelect2SearchText()
    fieldHandlers.client_ajax()
    fieldHandlers.toggleHeader()
    fieldHandlers.toggleSubHeader()
  },
}

function _btnResetHandler (options) {
  const $form = $('#report_form')
  $('#report_reset').on('click', function () {
    $form.find('input:not(.boolean input)').val('')

    $form.find('select option:selected').each(function () {
      $(this).removeAttr('selected')
      $(this).trigger('change')
    })

    $form.find('select').trigger('change')

    $form.find('.checkbox').each(function () {
      $(this).attr('checked', false)
      $(this).trigger('change')
    })

    _daterangepickerInit(options)

    $('#report_table_container').empty()
    $('.report-subtitle').empty()
    $('#chart_container').empty()
    $('.chart-item').empty().hide()
    $('#report_right_container').empty()
  })
}

function _daterangepickerInit (options = {}) {
  $(function () {
    const $period = $('#period')
    const start = options.start || moment().startOf('month')
    const end = options.end || moment().endOf('month')

    $('#single_date').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: 'D MMMM YYYY',
        separator: ' - ',
        applyLabel: t('apply'),
        cancelLabel: t('cancel'),
        fromLabel: t('date_rage_picker.from'),
        toLabel: t('date_rage_picker.to'),
        customRangeLabel: t('date_rage_picker.custom'),
      },
    })

    function cb (start, end) {
      $period.html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
    }

    $period.daterangepicker(Utils.getDateRangePickerConfig({
      startDate: start,
      endDate: end,
    }), cb)

    cb(start, end)
  })
}

// fields handlers

function FieldHandlers () {}

FieldHandlers.prototype.entry_type_ajax = () => {
  $('.entry_type_ajax').select2({
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 3,
    ajax: {
      url: Routes.search_select2_entry_types_path(),
      dataType: 'json',
      delay: 250,
    },
  })
}

FieldHandlers.prototype.entry_types_ajax = () => {
  $('.entry_types_ajax').select2({
    width: '100%',
    placeholder: t('all_price_positions'),
    dropdownAutoWidth: true,
    multiple: true,
    minimumInputLength: 3,
    ajax: {
      url: Routes.search_select2_entry_types_path(),
      dataType: 'json',
      delay: 250,
      cache: true,
    },
  })
}

FieldHandlers.prototype.client_ajax = () => {
  $('.client_ajax').select2({
    width: '100%',
    placeholder: t('all_patients'),
    dropdownAutoWidth: true,
    multiple: false,
    minimumInputLength: 3,
    allowClear: true,
    ajax: {
      url: '/clients/search_select2',
      dataType: 'json',
      delay: 250,
    },
  })
}

/**
 * FieldHandlers.prototype.entry_type_categories используется в отчетах:
 * - Продажи
 * - Категории
 * - Лаборатории
 * - Динамика: Категории
 * - Динамика: Услуги
 * - Источники привлечения
 * - Рефералы
 */
FieldHandlers.prototype.entry_type_categories = () => {
  if ($('.entry_type_categories').length) {
    $.ajax({
      url: Routes.category_tree_for_select2_categories_path(),
      method: 'get',
      success (res) {
        $('.entry_type_categories').select2ToTree({ treeData: { dataArr: res }, width: '100%' })
      },
      error (err) {
        console.error(err)
      },
    })
  }
}

FieldHandlers.prototype.selection = () => {
  $('.selectToSelect2').select2({
    width: '100%',
  })
}

FieldHandlers.prototype.select2hideDropdown = () => {
  $('body').on('mouseleave', '.select2-dropdown', () => {
    $('.select2-hidden-accessible').select2('close')
  })
}

FieldHandlers.prototype.fixSelect2SearchText = () => {
  $('body').on('select2:open', 'select[multiple]', function () {
    $('.select2-search__field').each(function () {
      const select = $(this).parents('.form-group').find('select').data('select2')
      if (select) $(this).attr('placeholder', select.selection.placeholder.text)
    })
  })
}

FieldHandlers.prototype.toggleHeader = () => {
  const $headers = $('.list-index-header')

  $headers.each(function () {
    const $header = $(this)
    const subHeaderGoesNext = $header.next().hasClass('list-index-sub_header')
    const catalogItemGoesNext = $header.next().hasClass('catalog-item')

    if (subHeaderGoesNext) {
      if (!$header.next().next().hasClass('catalog-item')) $header.hide()
    } else {
      if (!catalogItemGoesNext) $header.hide()
    }
  })
}

FieldHandlers.prototype.toggleSubHeader = () => {
  const $subHeaders = $('.list-index-sub_header')

  $subHeaders.each(function () {
    const catalogItemGoesNext = $(this).next().hasClass('catalog-item')
    if (!catalogItemGoesNext) {
      $(this).hide()
    }
  })
}
