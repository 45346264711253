Utils.LocalStorage = (function () {
  var ut = {}

  ut.update = function () {
    $('.u-local-storage').each(function () {
      var $this = $(this)
      var storageName = $this.data('storage-name')
      var val = store.get(storageName)

      if ($this.is(':checkbox')) {
        if (val !== undefined) $this[0].checked = val

        $this.change()
      } else {
        if (val) {
          $this.val(val).change()
        }
      }

      $this.on('change', function () {
        var val
        if ($this.is(':checkbox')) {
          val = $this[0].checked
        } else {
          val = $this.val()
        }
        store.set(storageName, val)
      })
    })
  }

  ut.loadGridCheck = function () {
    $('#change_step').each(function () {
      var $this = $(this)
      var storageName = $this.data('storage-name')
      var storedVal = store.get(storageName)
      var arr = []

      $('#change_step option').each(function () {
        arr.push(this.text)
      })

      if (!arr.includes(storedVal)) {
        store.set(storageName, null)
        $('#change_step :first').attr('selected', 'selected').change()
      }
    })
  }

  return ut
})()
