//
// some helper objects
//

export const Current = (n) => {
  return {
    draw () {
      return `
        <li class="active">
          <span>${n}</span>
        </li>
      `
    },
    toString () {
      return String('ccc')
    },
  }
}

export const Page = (n) => {
  return {
    draw () {
      return `
        <li>
          <a href="#"
             class="js-pagination-page"
             data-type="page"
             data-page="${n}"
          >
            ${n}
          </a>
        </li>
      `
    },
    toString () {
      return String(n)
    },
  }
}

export const Prev = ({disabled} = {}) => {
  return {
    draw () {
      return `
        <li class="prev ${disabled ? 'disabled' : ''}">
          <a href="#" data-type="prev">
            <span>
              <span class="fad fa-chevron-left"></span>
            </span>
          </a>
        </li>
      `
    },
    toString: () => '',
  }
}

export const Next = ({disabled} = {}) => {
  return {
    draw () {
      return `
        <li class="next ${disabled ? 'disabled' : ''}">
          <a href="#" data-type="next">
            <span>
              <span class="fad fa-chevron-right"></span>
            </span>
          </a>
        </li>
      `
    },
    toString: () => '',
  }
}

export const Placeholder = () => {
  return {
    draw () {
      return `<li class="disabled"><a href="#">...</a></li>`
    },
    toString () {
      return '...'
    },
  }
}
