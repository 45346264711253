Utils.UISettings = (function () {
  var navbarStatus
  var storeName

  function init () {
    storeName = 'user_ui'
    if (!store.get(storeName)) store.set(storeName, {})
    if (!store.get(storeName).navbar) {
      store.set(storeName, {navbar: {status: ''}})
    }

    navbarStatus = store.get('user_ui').navbar.status

    if (navbarStatus === 'rolled') {
      navbarRollHandler()
    }

    $('.btn-roll-navbar').on('click', function () {
      navbarRollHandler()
    })
  }

  function navbarRollHandler () {
    $('.btn-roll-navbar').toggle()
    $('.navbar ').toggleClass('rolled')
    $('body').toggleClass('navbar-rolled')

    Utils.resizeToFullscreen()

    if ($('.navbar ').hasClass('rolled')) {
      navbarStatus = 'rolled'
    } else {
      navbarStatus = ''
    }

    store.set('user_ui', {navbar: {status: navbarStatus}})
  }

  return {
    init,
  }
})()
