import {
  CALL_NTF_CLASS,
  CALL_NTF_PRESET_NAME,
  CALL_NTF_STATUS_RESULT,
  FINISHED_CALL_STATUS,
} from '../telephony/const.js'

window.Notify = (function () {
  let _store = []
  const MAX_NTF_COUNT = 5
  const ALWAYS_SHOWED_NTF = [CALL_NTF_PRESET_NAME]
  let ShowRefuseModalAgain = false

  let
    init,
    create,
    close,
    closeAll,
    closeNotification,
    _notifyFromCache,
    _saveCache,
    _generateCompactParams,
    _getSettingsFromConfiguration,
    _returnSetting,
    _isNtfExists,
    _getNtfConfigKeys,
    _resolveCustomSettings,
    _checkPresence,
    convertToMillisecond

  const onClickPresets = {
    appointment (data, ntf) {
      switch (data.status) {
        case 'arrived':
          const path = Routes.medical_record_path(data.medical_record_id)
          window.open(path, '_blank')
          break
        case 'serviced':
          Services.pubSub.emit('LastAppointments.open', data.client_id)
      }
      ntf.close()
    },
    reminder (data, ntf) {
      Notification.openReminderForm(data.reminder_id)
      Notification.dismiss(data.id)
      ntf.close()
    },
    compact (data, ntf) {
      Notification.openModal()
      ntf.close()
    },
    call (data, ntf) {
      if (data.status === CALL_NTF_STATUS_RESULT) {
        ShowRefuseModalAgain = true
        subscribeCallUpdate()
        openCallResultForm(data.call_id)
        ntf.close()
      } else {
        CallModule.init()
        CallModule.show(data.call_id)
      }
    },
    sms (data, ntf) {
      window.open(data.onclick_url, '_blank')
      ntf.close()
    },
    client_feedback (data, ntf) {
      if (data.type === 'notify_responsibles_for_new_client_feedbacks') {
        Services.pubSub.emit('CLIENT_FEEDBACKS_MODAL.OPEN', data.feedback_id)
      } else if (data.type === 'notify_new_responsible') {
        Services.pubSub.emit('CLIENT_FEEDBACKS_MODAL.OPEN', data.feedback_id)
      } else {
        console.warn('undefined preset type')
      }

      ntf.close()
    },
  }

  function subscribeCallUpdate () {
    Services.wsSubscriptions.calls.connect((msg) => {
      //open call result modal window
      const call = msg.data
      const id = $('#call_result_form')[0].action.split('/').slice(-1)[0]

      if (call.id == id && ShowRefuseModalAgain) {
        openCallResultForm(id)
      }
    })

    PubSub.on('page.form.call.modal.clickSave', (msg, data) => {
      ShowRefuseModalAgain = false
    })
  }

  function openCallResultForm (callId) {
    const callForm = FormFactory.build('call')

    $.ajax(Routes.call_path(callId), {
      success (callId) {
        callForm.editItem(callId)
        $('#call_refuse_result_from_modal').val('true').change()
      },
      error (err) {
        console.log(err)
      },
    })
  }

  init = function () {
    $.notifyDefaults({
      type: 'info',
      placement: { from: 'bottom', align: 'right' },
      delay: 0,
      offset: { x: 20, y: 20 },
    })
    _store = []
    if (HistoryBrowser.isAfterLogInPage()) {
      create({ forceCompact: true })
    } else {
      _notifyFromCache()
    }
  }

  _notifyFromCache = function () {
    const notifications = store.get('notifications') || []
    store.set('notifications', [])
    notifications.forEach(function (n) {
      create(n.params)
    })
  }

  create = function (params) {
    let presetName

    if (params.custom_settings) {
      if (params.custom_settings.preset_name) {
        presetName = params.custom_settings.preset_name
      }
    }

    if (!params) throw new Error('notification params variable is undefined!')
    if ((_store?.length >= MAX_NTF_COUNT || params.forceCompact ||
            _isNtfExists('compact')) && (ALWAYS_SHOWED_NTF.indexOf(presetName) === -1)) {
      params = _generateCompactParams()
      if (!params) return
    }

    if ((_store?.length >= MAX_NTF_COUNT)) {
      $('#close_all_notifications').show()
    }

    const options = params.options || {}
    const settings = params.settings || {}
    const customSettings = params.custom_settings || {}

    $.extend(settings, _getSettingsFromConfiguration(customSettings))

    settings.onClosed = function () {
      _store.splice(_store.indexOf(result), 1)
      _saveCache()
    }

    if (!settings.active) {
      return
    }

    const notification = $.notify(options, settings)
    const $ntf = notification.$ele

    $ntf.addClass(customSettings.preset_name + '_notification hidden-print')

    if ((customSettings.preset_name === CALL_NTF_PRESET_NAME)) {
      $ntf.addClass(customSettings.status)
      $ntf.data('call-id', customSettings.call_id)
      if (customSettings.status === FINISHED_CALL_STATUS) {
        $('.' + customSettings.type + '_call').filter(function () {
          return $(this).data('call-id') === customSettings.call_id
        }).find('[data-notify="dismiss"]').trigger('click')
      }
    }

    $ntf.click(function () {
      const onClickFunction = onClickPresets[customSettings.preset_name]
      if ($.isFunction(onClickFunction)) {
        onClickFunction(customSettings, notification)
      }
    })

    $ntf.on('click', '.close', function (e) {
      e.stopPropagation()
    })

    var result = {
      params,
      notification,
    }

    _store.push(result)
    _saveCache()

    return notification
  }

  _saveCache = function () {
    store.set('notifications', _store)
  }

  convertToMillisecond = function (value) {
    return value * 1000
  }

  _getSettingsFromConfiguration = function (customSettings) {
    const resolvedCustomSettings = _resolveCustomSettings(customSettings)

    const settings = _returnSetting(resolvedCustomSettings.preset_name, resolvedCustomSettings.status)
    settings.delay = convertToMillisecond(settings.delay)

    return settings
  }

  _resolveCustomSettings = function (customSettings) {
    const defaultSettings = {
      preset_name: 'various',
      status: 'default',
    }
    const resolvedSettings = {}

    if (_checkPresence(customSettings.preset_name)) {
      resolvedSettings.preset_name = customSettings.preset_name
      if (_checkPresence(customSettings.status, resolvedSettings.preset_name)) {
        resolvedSettings.status = customSettings.status
      } else {
        return defaultSettings
      }
    } else {
      if (_checkPresence(customSettings.preset_name, defaultSettings.preset_name)) {
        resolvedSettings.preset_name = defaultSettings.preset_name
        resolvedSettings.status = customSettings.preset_name
      } else {
        return defaultSettings
      }
    }

    return resolvedSettings
  }

  _checkPresence = function (target, key = null) {
    if (key) {
      return _getNtfConfigKeys(key).indexOf(target) > -1
    }

    return _getNtfConfigKeys().indexOf(target) > -1
  }

  _getNtfConfigKeys = function (key = null) {
    const config = gon.application.notifications_configuration
    if (key) {
      return Object.keys(config[key])
    }

    return Object.keys(config)
  }

  _returnSetting = function (preset, type) {
    const config = gon.application.notifications_configuration

    return { ...config[preset][type] }
  }

  _generateCompactParams = function () {
    const ntfCount = Notification.getCount()
    let params

    closeAll()

    if (ntfCount) {
      params = {
        options: {
          message: t('reception.new_notifications') + ': ' + ntfCount,
          icon: 'fad fa-info-circle',
        },
        custom_settings: {
          preset_name: 'compact',
        },
      }
    }

    return params
  }

  _isNtfExists = function (ntfPresetName) {
    return (_store || []).some(function (e) {
      return e.params.custom_settings.preset_name === ntfPresetName
    })
  }

  close = function (id) {
    for (let i = 0; i < _store?.length || -1; i++) {
      if (_store[i].params.custom_settings.id === id) {
        _store[i].notification.close()
        break
      }
    }

    if (_isNtfExists('compact')) {
      create({ forceCompact: true })
    }
  }

  closeAll = function () {
    $('[data-notify]').not(CALL_NTF_CLASS).find('[data-notify="dismiss"]').trigger('click')
    $('[data-notify="container"]').not(CALL_NTF_CLASS).remove()
  }

  closeNotification = function ({ selector, filter }) {
    $(selector).filter(filter).find('[data-notify="dismiss"]').trigger('click')
  }

  return {
    init,
    create,
    close,
    closeAll,
    closeNotification,
  }
})()
