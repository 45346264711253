/**
 * @typedef {{
 *  addressCountry: string,
 *  addressZipCode: string,
 *  lastName: string,
 *  whoIssue: string,
 *  patientCardNumber: number,
 *  attractionSourcesId: number,
 *  clientGroupId: number,
 *  addressState: string,
 *  addressFlat: number,
 *  birthDate: string,
 *  firstName: string,
 *  companyId: number,
 *  phone: string,
 *  addressStreet: string,
 *  addressHouse: number,
 *  idCardSeries: string,
 *  idCardNumber: string,
 *  clientAdditional: string,
 *  issueDate: string,
 *  email: string,
 *  secondName: string,
 *  addressCity: string
 * }} ClientStub
 */
Utils.fakeForms = (function () {
  const streets = ['Ленина', 'Карла-Маркса', 'Пушкина', 'Ломоносова', 'Кутузова']
  const cities = ['Киров', 'Вятка', 'Василевск', 'Москва', 'Екатеринбург', 'Санкт-Петербург']
  const firstNames = ['Александр', 'Алексей', 'Альберт', 'Анатолий', 'Андрей', 'Антон', 'Артём', 'Аркадий', 'Арсений', 'Артур', 'Богдан', 'Борис', 'Валерий', 'Валентин', 'Василий', 'Вадим', 'Владимир', 'Владислав', 'Виктор', 'Виталий', 'Вячеслав', 'Григорий', 'Глеб', 'Герман', 'Георгий', 'Геннадий', 'Давид', 'Даниил', 'Дмитрий', 'Денис', 'Евгений', 'Егор', 'Захар', 'Иван', 'Игорь', 'Ильдар', 'Илья', 'Кирилл', 'Константин', 'Леонид', 'Марат', 'Марк', 'Максим', 'Михаил', 'Назар', 'Никита', 'Николай', 'Олег', 'Павел', 'Пётр', 'Ринат', 'Роберт', 'Роман', 'Руслан', 'Рустам', 'Святослав', 'Станислав', 'Степан', 'Сергей', 'Семён', 'Тарас', 'Тимофей', 'Филипп', 'Фёдор', 'Эрик', 'Эльдар', 'Эмиль', 'Эдуард', 'Юрий', 'Ярослав', 'Яков']
  const lastNames = ['Смирнов', 'Иванов', 'Кузнецов', 'Соколов', 'Попов', 'Лебедев', 'Козлов', 'Новиков', 'Морозов', 'Петров', 'Волков', 'Соловьёв', 'Васильев', 'Зайцев', 'Павлов', 'Семёнов', 'Голубев', 'Виноградов', 'Богданов', 'Воробьёв', 'Фёдоров', 'Михайлов', 'Беляев', 'Тарасов', 'Белов', 'Комаров', 'Орлов', 'Киселёв', 'Макаров', 'Андреев', 'Ковалёв', 'Ильин', 'Гусев', 'Титов', 'Кузьмин', 'Кудрявцев', 'Баранов', 'Куликов', 'Алексеев', 'Степанов', 'Яковлев', 'Сорокин', 'Сергеев', 'Романов', 'Захаров', 'Борисов', 'Королёв', 'Герасимов', 'Пономарёв', 'Григорьев']
  const secondNames = ['Анисимович', 'Антипович', 'Антонович', 'Ануфриевич', 'Арсенович', 'Арсеньевич', 'Артёмович', 'Артемьевич', 'Артурович', 'Архипович', 'Афанасьевич', 'Давидович', 'Давыдович', 'Даниилович', 'Данилович', 'Демидович', 'Демьянович', 'Денисович', 'Димитриевич', 'Вадимович', 'Валентинович', 'Валерианович', 'Валерьевич', 'Валерьянович', 'Васильевич', 'Венедиктович', 'Вениаминович', 'Викентьевич', 'Ермолаевич', 'Ерофеевич', 'Ефимович', 'Ефимьевич', 'Ефремович', 'Ефстафьевич', 'Викторович', 'Виленович', 'Вилорович', 'Витальевич', 'Владиленович', 'Владимирович', 'Владиславович', 'Владленович', 'Власович', 'Тарасович', 'Теймуразович', 'Терентьевич', 'Тимурович', 'Тихонович', 'Трифонович', 'Трофимович']
  let attractionSources, clientGroupIds, companyIds
  let isInitialized = false

  /**
   * @return {Promise<void>}
   */
  function fetchFakeClientData () {
    if (isInitialized) {
      return Promise.resolve()
    }

    return new Promise((resolve, reject) => {
      isInitialized = true
      $.ajax({
        method: 'post',
        url: Routes.utils_get_fake_data_path(),
        data: { model: 'client' },
        success (data) {
          attractionSources = data.attraction_sources_ids
          clientGroupIds = data.client_groups_ids
          companyIds = data.companies_ids
          resolve()
        },
        error (err) {
          isInitialized = false
          console.error(err)
          reject(err)
        },
      })
    })
  }

  /**
   * @return {Promise<ClientStub>}
   */
  function client () {
    const issueDate = faker.date.past(5, new Date('Sat Sep 20 2015 21:35:02 GMT+0200 (CEST)'))
    const birthdate = faker.date.past(50, new Date('Sat Sep 20 1992 21:35:02 GMT+0200 (CEST)'))

    return fetchFakeClientData().then(() => ({
      lastName: _.sample(lastNames),
      firstName: _.sample(firstNames),
      secondName: _.sample(secondNames),
      phone: faker.phone.phoneNumber('79#########'),
      email: faker.internet.password() + faker.internet.email(),
      birthDate: (birthdate.getDate() + 1).toString().padStart(2, '0') + '.' + (birthdate.getMonth() + 1).toString().padStart(2, '0') + '.' + birthdate.getFullYear(),
      clientAdditional: faker.lorem.sentence(),
      clientGroupId: _.sample(clientGroupIds),

      // address
      addressCountry: 'Россия',
      addressZipCode: faker.address.zipCode(),
      addressCity: _.sample(cities),
      addressState: faker.address.state(),
      addressStreet: streets.sample(),
      addressHouse: faker.random.number(999),
      addressFlat: faker.random.number(99),

      // idCard
      idCardSeries: faker.random.number(9999),
      idCardNumber: faker.random.number(999999),
      whoIssue: 'ОВД Ленинского р-на',
      issueDate: issueDate.getDate() + '/' + issueDate.getMonth() + '/' + issueDate.getFullYear(),

      // patientCard
      patientCardNumber: faker.random.number(9999),

      // work
      companyId: _.sample(companyIds),

      // appointment
      attractionSourcesId: _.sample(attractionSources),
    }))
  }

  return {
    client,
  }
})()
