// Модуль вывода табличек с сортировкой

import paginationGenerator from '../plugins/paginationGenerator'
import { request } from '@/lib/transport/request'
import { identity } from 'lodash/util'

const DEFAULT_PER_PAGE = 50
const DEFAULT_PAGE = 1
window.SortItems = (function () {
  const NOT_CACHING_CACHE_NAMES = ['sort_/entries'] // ключи local_storage,которые не всегда нужно сохранять

  let init
  let refresh
  let _btnPeriodHandler
  let _btnLetterHandler
  let _btnFindOutdatedHandler
  let _dateHandler
  let _btnResetHandler
  let _btnTeethHandler
  let _refreshHandler

  const jQM = {}
  let prmMap = {} // параметры для сортировки
  const optMap = {} // значения инициализации

  let lastQueryParams = {} // save it for further pagination
  let lastAction = 'sort'

  let _dateValue // хак для исправления троекратного запроса в БД
  let typingTimer
  let timeOut

  init = function (options) {
    optMap.itemName1 = options.itemName[0] // имя контроллера
    optMap.itemName2 = options.itemName[1] // если в котроллере две сущности
    optMap.cacheName = 'sort_' + options.url // задаем имя объекта в кеше
    optMap.url = options.url
    optMap.searchMinCharactersLength = options.searchMinCharactersLength || 2
    optMap.defaults = options.defaults || {} // значения сортировки по умолчанию
    optMap.periodRequired = options.periodRequired
    optMap.onDeleteError = options.onDeleteError
    optMap.dataAdapter = options.dataAdapter || identity
    optMap.stopSearch = options.stopSearch
    jQM.itemsListWrap = $('.items-list-container')
    jQM.paginationContainer = $('.items-list-pagination-container')
    jQM.itemsTable = $('.items-table')
    jQM.itemsSortControlArea = $('.items-sort-control-area')
    jQM.btnPeriod = $('.btn-period')
    jQM.checkboxOnlyMine = $('.checkbox-only-mine')
    jQM.btnLetter = $('.letter')
    jQM.teethContainer = $('#entry_tooth_select')
    jQM.inputClient = $('.input_sort_client')
    jQM.search = $('.search')
    jQM.date = $('#date')
    jQM.selectors = jQM.itemsSortControlArea.find('select')
    jQM.findOutdatedBtn = $('#find_outdated_btn')
    jQM.resetBtn = $('#reset_btn')

    if (!optMap.defaults.hasOwnProperty('page')) {
      optMap.defaults.page = DEFAULT_PAGE
    }
    _daterangepickerInit({
      autoUpdateInput: false,
    })

    _setEvents() // вешаем события на кнопки и инпуты

    _pastFromCache() // вставляем значения из кеша в элементы интерфейса
  }

  refresh = function () {
    _refreshHandler($(this))
  }

  function _setEvents () {
    /// ///элементы формы////////

    jQM.btnPeriod.on('click', function () {
      _btnPeriodHandler($(this))
    })

    jQM.date.on('change', function () {
      if ($(this).val() !== _dateValue) {
        _dateValue = $(this).val()
        _dateHandler($(this))
      }
    })

    jQM.btnLetter.on('click', function () {
      _btnLetterHandler($(this))
    })

    jQM.findOutdatedBtn.on('click', function () {
      _btnFindOutdatedHandler($(this))
    })

    jQM.checkboxOnlyMine.each(function (index, el) {
      $(el).on('change', function () {
        _takeSortParams()
        _sortItemsFromDB(prmMap)
        lastAction = 'sort'
      })
    })

    jQM.teethContainer.on('click', '.dent-tooth-container', function () {
      _btnTeethHandler($(this))
    })

    jQM.inputClient.each(function (index, el) {
      $(el).on('change', function () {
        _takeSortParams()
        _sortItemsFromDB(prmMap)
        lastAction = 'sort'
      })
    })

    jQM.selectors.each(function (index, el) {
      $(el).on('change', function () {
        _takeSortParams()
        _sortItemsFromDB(prmMap)
        lastAction = 'sort'
      })
    })

    jQM.resetBtn.on('click', function () {
      _btnResetHandler($(this))
    })

    /// ///end элементы формы////////

    /// //поиск/////

    jQM.search.on('keyup', function (e) {
      if (optMap.stopSearch) { return }

      timeOut = 1000
      clearTimeout(typingTimer)

      // если нажат Enter ищем сразу
      if (e.which === 13) {
        timeOut = 0
      }

      // если в строке поиска более трех символов
      if ($(this).val().length >= optMap.searchMinCharactersLength) {
        const self = $(this)
        typingTimer = setTimeout(function () {
          _searchItemsFromDB(self.val())
          lastQueryParams = self.val()
          lastAction = 'search'
        }, timeOut)
      }

      // если значение в строке поиска стерто
      if ($(this).val().length === 0) {
        typingTimer = setTimeout(function () {
          _pastFromCache()
          _takeSortParams()
          _sortItemsFromDB(prmMap)
          lastAction = 'sort'
        }, 1000)
      }
    })

    /// //end поиск/////
  }

  /// //////HANDLERS/////////////

  _btnPeriodHandler = function (self) {
    jQM.btnPeriod.removeClass('active')
    self.addClass('active')
    jQM.date.val('')
    _takeSortParams()
    _sortItemsFromDB(prmMap)
    lastAction = 'sort'
  }

  _dateHandler = function (self) {
    jQM.btnPeriod.removeClass('active')
    _takeSortParams()
    _sortItemsFromDB(prmMap)
    lastAction = 'sort'
  }

  _btnTeethHandler = function (self) {
    self.toggleClass('selected')
    _takeSortParams()
    _sortItemsFromDB(prmMap)
    lastAction = 'sort'
  }

  _btnLetterHandler = function (self) {
    jQM.btnLetter.not(self).removeClass('active')
    self.addClass('active')
    _takeSortParams()
    _sortItemsFromDB(prmMap)
    lastAction = 'sort'
  }

  _btnFindOutdatedHandler = function (self) {
    _takeSortParams()
    _sortItemsFromDB(prmMap)
    lastAction = 'sort'
  }

  _btnResetHandler = function (self) {
    jQM.itemsSortControlArea.find('input.search').val('')
    jQM.btnPeriod.removeClass('active')
    jQM.findOutdatedBtn.prop('checked', false)
    jQM.date.val('')
    jQM.checkboxOnlyMine.prop('checked', false)
    jQM.teethContainer.find('.dent-tooth-container').removeClass('selected')

    jQM.itemsSortControlArea.find('select').each(function () {
      $(this).val($(this).find('option:first').val())
      // $(this).trigger('change')
    })

    _insertValues(optMap.defaults)
    _takeSortParams()
    _sortItemsFromDB(prmMap)
    lastAction = 'sort'

    // jQM.itemsSortControlArea.find('select.select2-hidden-accessible').val('').trigger('change.select2');
  }

  _refreshHandler = function (self) {
    _takeSortParams()
    _sortItemsFromDB(prmMap)
    lastAction = 'sort'
  }

  /// //////METHODS/////////////

  function _writeToCache () {
    store.set(optMap.cacheName, $.extend(true, {}, prmMap))
    store.get(optMap.cacheName)
  }

  // вставляем значения из кеша в элементы интерфейса
  function _pastFromCache () {
    _checkCache()
    const paramsFromStore = store.get(optMap.cacheName) || optMap.defaults
    prmMap = paramsFromStore
    _insertValues(paramsFromStore)
    _takeSortParams()
    _sortItemsFromDB(prmMap, { page: optMap.defaults.page })
  }

  function _checkCache () {
    NOT_CACHING_CACHE_NAMES.forEach((item) => {
      if (item !== optMap.cacheName) {
        store.remove(item)
      }
    })
  }

  const __setPeriod = ({ period, date }) => {
    if (date && jQM.date.length) {
      jQM.date.val(date)

      return
    }

    const filter = jQM.btnPeriod.filter('#' + period)
    if (filter.length) {
      filter.addClass('active')

      return
    }

    jQM.btnPeriod.filter('#today').addClass('active')
  }

  function _insertValues (paramsFromStore) {
    const s = paramsFromStore

    // https://app.clickup.com/t/48f4j4
    __setPeriod(s || {})

    if (s.hasOwnProperty('only_mine')) {
      jQM.checkboxOnlyMine.prop('checked', s.only_mine)
    }

    jQM.search.val(s.search_val)

    $(`.letter:contains('${s.letter}')`).addClass('active')

    jQM.selectors.each(function (num, el) {
      const id = $(el).attr('id')
      $(el).val(s[id]).trigger('change.select2')
    })
    if (!paramsFromStore.hasOwnProperty('date')) {
      jQM.date.val('')
    }

    if (s.hasOwnProperty('teeth_ids')) {
      jQM.teethContainer.find('.dent-tooth-container').filter(function () {
        return s.teeth_ids.includes($(this).data('number'))
      }).addClass('selected')
    }
  }

  function _findSelectedTeeth () {
    const teethIds = []

    jQM.teethContainer.find('.dent-tooth-container').filter('.selected').each(function () {
      teethIds.push($(this).data('number'))
    })

    return teethIds.length > 0 ? teethIds : null
  }

  // берем значения кнопок для сортировки
  function _takeSortParams () {
    jQM.search.val('')
    // grab all params
    prmMap = {
      date: jQM.date.val(),
      letter: jQM.btnLetter.filter('.active').text(),
      search_val: jQM.search.val(),
      period: jQM.btnPeriod.filter('.active').attr('id'),
      find_outdated: jQM.findOutdatedBtn.filter(':checked').attr('id'),
      only_mine: jQM.checkboxOnlyMine.is(':checked'),
      teeth_ids: _findSelectedTeeth(),
      client_id: jQM.inputClient.val(),
    }

    // пробегаемся по всем select-ам и вставляем значения в карту параметров
    jQM.selectors.each(function (num, el) {
      const id = $(el).attr('id')
      const val = $(el).val()
      prmMap[id] = val
    })

    // remove empty params from map
    for (const param in prmMap) {
      if (!prmMap[param]) delete prmMap[param]
    }

    lastQueryParams = $.extend({}, prmMap)
    _writeToCache()
  }

  function _sortItemsFromDB (data, paginationParams = {}) {
    const { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE } = paginationParams
    data = Object.assign({}, data, { page, per_page: perPage })
    if (optMap.periodRequired && !data.date && !data.period) {
      Notificator.error(t('сhoose_period_or_date'))

      return
    }

    jQM
      .itemsSortControlArea
      .find('span, input, select')
      .attr('disabled', 'disabled')

    _startSpin()

    $.ajax({
      type: 'post',
      url: `${optMap.url}/sort`,
      data,
      success (items) {
        _drawItemsList(optMap.dataAdapter(items))
        Utils.updateTooltips(document.querySelector('.sort_items'))
        _itemBtnHandler()
        PubSub.emit('_sortItemsFromDB', items)
      },
      error (err) {
        console.log(err)
      },
      complete () {
        jQM
          .itemsSortControlArea
          .find('span, input, select')
          .not('.visibility-dependent')
          .removeAttr('disabled')
        _stopSpin()
      },
    })
  }

  function _searchItemsFromDB (data, paginationParams = {}) {
    const { page = 1, perPage = DEFAULT_PER_PAGE } = paginationParams
    data = Object.assign({ search_text: data }, { page, per_page: perPage })

    prmMap.search_val = jQM.search.val()
    _writeToCache()

    jQM
      .itemsSortControlArea
      .find('span, input, select')
      .attr('disabled', 'disabled')

    jQM.itemsSortControlArea.find('*').removeClass('active')
    _startSpin()
    $.ajax({
      type: 'post',
      url: `${optMap.url}/search`,
      data,
      success (items) {
        _drawItemsList(optMap.dataAdapter(items))
      },
      error (err) {
        console.log(err)
      },
      complete () {
        jQM
          .itemsSortControlArea
          .find('span, input, select')
          .not('.visibility-dependent')
          .removeAttr('disabled')
        _stopSpin()
      },
    })
  }

  function _sendEvents () {
    PubSub.emit('page.specific.sort_items.finish')
  }

  function _drawItemsList (items) {
    // var dataItems = items.data
    jQM.itemsTable.html('')
    const source = $('#items_list_tmpl').html()
    const template = Handlebars.compile(source)
    jQM.itemsTable.append(template(items))

    jQM.paginationContainer
      .empty()
      .append(generatePagination({
        current: items.page,
        total: items.total_pages,
      }))

    gon.page.params.page = items.page

    if (items.data.length === 0) {
      jQM
        .itemsTable
        .html(`<p><div class="no-data-found prompt-notice">${t('nothing_has_found')}</div></p>`)
    }
    _sendEvents()
  }

  function _itemBtnHandler () {
    $('.jq-item-edit').on('click', function (e) {
      e.stopPropagation()
      e.preventDefault()
      if (!$(this).hasClass('link-disabled')) {
        const link = $(this).attr('data-href')

        this.dataset?.turbolinks /* ожидается string */
          ? window.location.href = link
          : Turbolinks.visit(link)
      }
    })

    $('.jq-item-delete').on('click', function (e) {
      e.stopPropagation()
      e.preventDefault()
      const self = this
      if (!$(this).hasClass('link-disabled')) {
        Utils.askDestroy({
          url: $(self).data('href'),
          success () {
            Notificator.info(t('record_delete'))
            refresh()
          },
          error: optMap.onDeleteError,
          title: $(self).data('entry-title'),
        })
      }
      $('.bootbox-body').parent().css('overflow-y', 'hidden')
    })
    $('.change-status').on('click', function (e) {
      e.preventDefault()
      e.stopPropagation()
      const params = { entry: { state: $(this).data('reverse-status') } }
      const parent = $(this).closest('tr')

      request({
        type: 'PATCH',
        url: Routes.entry_path($(this).data('id')),
        data: params,
      }).promise
        .then(() => {
          Notificator.info(t('entry_status_changed'))
          if (parent.hasClass('active')) {
            Turbolinks.visit(parent.attr('data-href'))
          } else {
            refresh()
          }
        })
        .catch((err) => {
          Notificator.error(
            err?.responseJSON?._popup_ ||
            t('insufficient_access_rights')
          )
        })
    })
  }

  const generatePagination = ({ current, total }) => {
    return paginationGenerator({
      current,
      total,
      onNav (pageNumber) {
        if (lastAction === 'sort') {
          _sortItemsFromDB(lastQueryParams, { page: pageNumber })
        } else if (lastAction === 'search') {
          _searchItemsFromDB(lastQueryParams, { page: pageNumber })
        } else {
          console.log('last sort action unknown', lastAction)
        }
      },
    })
  }

  /// //////METHODS END/////////////

  /// ///////////////////////////////

  function _startSpin () {
    jQM.itemsListWrap.loadSpin('start')
  }

  function _stopSpin () {
    jQM.itemsListWrap.loadSpin('stop')
  }

  function _daterangepickerInit (options = {}) {
    const BACKSPACE_KEY = 8
    const DELETE_KEY = 46

    jQM.date.keyup(function (event) {
      if (event.keyCode === BACKSPACE_KEY || event.keyCode === DELETE_KEY) {
        $(event.currentTarget).val('')
      }
    })

    $(function () {
      jQM.date.daterangepicker(Utils.getDateRangePickerConfig(options))
    })

    jQM.date.on('apply.daterangepicker', function (ev, picker) {
      $(this).val(picker.startDate.format('D MMMM YYYY') + ' - ' + picker.endDate.format('D MMMM YYYY'))
      _dateHandler($(this))
    })
  }

  /// //////////////////////////////

  return {
    init,
    refresh,
  }
})()
