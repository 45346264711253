window.tipNotify = function (entryType, configOptions = {}, configSetting = {}) {
  if (!entryType) console.error(t('not_passed_EntryType'))
  if (!entryType.tip.length) return
  if (!window.EmessageInstances) window.EmessageInstances = []

  if (window.EmessageInstances.length > 0) {
    window.EmessageInstances.shift().close()
  }

  window.EmessageInstances.push(
    window.Emessage({
      message: entryType.tip,
      type: configOptions.type || 'warning',
      showClose: configOptions.showClose || true,
      duration: configOptions.duration || 60000,
      customClass: configOptions.customClass || 'emessage-top-right',
      iconClass: configOptions.iconClass || 'fad fa-fw fa-info-circle',
    })
  )
}
