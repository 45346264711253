<template>
  <div
    id="clients_table"
    class="items-list-container-marketing"
  >
    <reminder-modal
      v-bind="reminderModal"
      @close="reminderModal.visibility = false"
      @updated="reminderUpdated"
    />
    <si>
      <si-table
        :spinner="waitingLoadClients"
      >
        <template #header>
          <si-field>
            <div class="tbl-icon">
              {{ tableHeader[0] }}
            </div>
            <div class="marketing-fullname cut">
              {{ tableHeader[1] }}
            </div>
            <div class="marketing-reminder">
              {{ tableHeader[2] }}
            </div>
            <div class="marketing-date">
              {{ tableHeader[3] }}
            </div>
            <div class="marketing-phone">
              {{ tableHeader[4] }}
            </div>
            <div class="marketing-email">
              {{ tableHeader[5] }}
            </div>
            <div class="marketing-paidSum">
              {{ tableHeader[6] }}
              <footnote :text="t('payment_entries_sum')" />
            </div>
          </si-field>
        </template>
        <template #body>
          <si-field
            v-for="(client, index) in tableRows"
            :key="index"
            :data-client-id="client.id"
          >
            <div class="tbl-icon">
              <i
                class="fad fa-bars client-actions"
              />
            </div>
            <div class="marketing-fullname cut">
              <span>
                {{ client.fullName }}
              </span>
            </div>
            <div class="marketing-reminder">
              <div
                v-if="client.reminder"
                class="reminder-cell-data-container"
              >
                <div class="reminder-cell-data-container-cell1">
                  <span class="next_reminder_status">
                    <span :class="`reminder-status-icon ${reminderStatusIcons[client.reminder.status]}`" />
                  </span>
                  <span class="next_reminder_type">
                    <span :class="reminderTypeIcons[client.reminder.reminderType]" />
                  </span>
                  <span
                    class="next_reminder_date"
                    @click="showUpdateReminderModal(client.reminder)"
                  >
                    {{ client.reminder.date | dateCorrection }}
                  </span>
                  <span class="next_reminder_title">
                    {{ client.reminder.title }}
                  </span>
                </div>
                <div class="reminder-cell-data-container-cell2">
                  <span
                    class="add-reminder-circle fad fa-plus-circle"
                    @click="showCreateReminderModal(client.id)"
                  />
                </div>
              </div>
              <div
                v-else
                class="no-reminder"
              >
                <span
                  class="add-reminder-text"
                  @click="showCreateReminderModal(client.id)"
                >
                  {{ T.add_reminder }}
                </span>
              </div>
            </div>
            <div class="marketing-date">
              <span>
                {{ client.birthDate | dateCorrection }}
              </span>
            </div>
            <div class="marketing-phone">
              <span
                class="phone_number callable ignore-click"
                :data-client-id="client.id"
                @click="activatePhonePopover(client.phone, $event)"
              >
                {{ client.phone | phoneNumber }}
              </span>
            </div>
            <div class="marketing-email">
              <span>
                {{ client.email }}
              </span>
            </div>
            <div class="marketing-paidSum">
              <span>
                {{ client.paidSum | price }}
              </span>
            </div>
          </si-field>
        </template>
        <template #footer />
      </si-table>
      <si-footer>
        <pagination
          :current-page.sync="currentPage"
          :page-count="pageCount"
          layout="prev, pager, next"
          @current-change="getData"
        />
      </si-footer>
    </si>
  </div>
</template>

<script>
import Pagination from '@/vue_components/common/pagination.vue'
import ReminderModal from './reminder_modal.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import Footnote from '@/vue_components/common/footnote.vue'
import withTooltips from '@/vue_components/mixins/withTooltips'
import formatter from '../../lib/formatters/formatter'

const LIMIT = 100

export default {
  name: 'ClientDashboard',

  components: {
    Pagination,
    ReminderModal,
    Si,
    SiTable,
    SiField,
    SiFooter,
    Footnote,
  },

  filters: {
    dateCorrection (date) {
      return formatter.formatValue(date, ['date:localDate'])
    },
    phoneNumber (phone) {
      return phone && phone.length ? `+${Services.phoneMask.setMask(phone)}` : ''
    },
    price (value) {
      return formatter.formatValue(value, ['price'])
    },
  },

  mixins: [withTooltips],

  props: {
    clientAgeAfter: {
      type: String,
      default: '',
    },
    clientAgeBefore: {
      type: String,
      default: '',
    },
    clientBirthDate: {
      type: Array,
      default: null,
    },
    clientCities: {
      type: Array,
      default: null,
    },
    clientSexIds: {
      type: Array,
      default: null,
    },
    clientGroupIds: {
      type: Array,
      default: null,
    },
    clientCompanyIds: {
      type: Array,
      default: null,
    },
    clientDoctorIds: {
      type: Array,
      default: null,
    },
    clientAmountOfPaymentsAfter: {
      type: String,
      default: '',
    },
    clientAmountOfPaymentsBefore: {
      type: String,
      default: '',
    },
    clientHasEmail: {
      type: String,
      default: '',
    },
    clientSmsAllowed: {
      type: String,
      default: '',
    },
    clientEmailAllowed: {
      type: String,
      default: '',
    },
    clientCallsAllowed: {
      type: String,
      default: '',
    },
    clientHasNotVisited: {
      type: Object,
      default: null,
    },
    clientHasUpcomingAppointments: {
      type: String,
      default: null,
    },
    clientHasNoUpcomingAppointments: {
      type: String,
      default: null,
    },
    appointmentClinicIds: {
      type: Array,
      default: null,
    },
    appointmentDateIncludes: {
      type: Array,
      default: null,
    },
    appointmentDateExcludes: {
      type: Array,
      default: null,
    },
    appointmentTypeIds: {
      type: Array,
      default: null,
    },
    appointmentAttractionSourceIds: {
      type: Array,
      default: null,
    },
    appointmentDoctorIds: {
      type: Array,
      default: null,
    },
    appointmentDoctorSpecialtyIds: {
      type: Array,
      default: null,
    },
    appointmentsWithPendingStatuses: {
      type: String,
      default: '',
    },
    visitDate: {
      type: Object,
      default: null,
    },
    reminderDate: {
      type: Object,
      default: null,
    },
    pendingReminders: {
      type: String,
      default: '',
    },
    entryDate: {
      type: Object,
      default: null,
    },
    entryEntryTypeIds: {
      type: Array,
      default: null,
    },
    entryDiseaseIds: {
      type: Array,
      default: null,
    },
    tableHeader: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    reminderModal: {
      visibility: false,
      action: '',
      reminder: null,
    },
    waitingLoadClients: false,
    LIMIT,
    currentPage: 1,
    pageCount: 0,
    count: 0,
    tableRows: [],
    reminderStatusIcons: [
      'fad fa-square fa-swap-opacity',
      'fad fa-check-square',
      'fad fa-pause',
      'fad fa-exclamation-circle',
      'fad fa-ban',
    ],
    reminderTypeIcons: [
      'fad fa-phone',
      'fad fa-user-hard-hat',
      'fad fa-envelope',
    ],
  }),

  watch: {
    count () {
      this.pageCount = Math.ceil(this.count / LIMIT)
    },
  },

  created () {
    this.getData()
  },

  updated () {
    this.updateTooltips()
  },

  methods: {
    getData () {
      if (this.waitingLoadClients) return
      this.waitingLoadClients = true

      const data = {
        clientAgeAfter: this.clientAgeAfter,
        clientAgeBefore: this.clientAgeBefore,
        clientBirthDate: this.clientBirthDate,
        clientCities: this.clientCities,
        clientSexIds: this.clientSexIds,
        clientGroupIds: this.clientGroupIds,
        clientCompanyIds: this.clientCompanyIds,
        clientDoctorIds: this.clientDoctorIds,
        clientAmountOfPaymentsAfter: this.clientAmountOfPaymentsAfter,
        clientAmountOfPaymentsBefore: this.clientAmountOfPaymentsBefore,
        clientHasEmail: this.clientHasEmail,
        clientSmsAllowed: this.clientSmsAllowed,
        clientEmailAllowed: this.clientEmailAllowed,
        clientCallsAllowed: this.clientCallsAllowed,
        clientHasNotVisited: this.clientHasNotVisited,
        clientHasUpcomingAppointments: this.clientHasUpcomingAppointments,
        clientHasNoUpcomingAppointments: this.clientHasNoUpcomingAppointments,
        appointmentClinicIds: this.appointmentClinicIds,
        appointmentDateIncludes: this.appointmentDateIncludes,
        appointmentDateExcludes: this.appointmentDateExcludes,
        appointmentTypeIds: this.appointmentTypeIds,
        appointmentAttractionSourceIds: this.appointmentAttractionSourceIds,
        appointmentDoctorIds: this.appointmentDoctorIds,
        appointmentDoctorSpecialtyIds: this.appointmentDoctorSpecialtyIds,
        visitDate: this.visitDate,
        reminderDate: this.reminderDate,
        pendingReminders: this.pendingReminders,
        entryDate: this.entryDate,
        entryEntryTypeIds: this.entryEntryTypeIds,
        entryDiseaseIds: this.entryDiseaseIds,
        offset: (this.currentPage - 1) * LIMIT,
        limit: LIMIT,
      }

      fetch(Routes.client_dashboard_path(), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        credentials: 'same-origin',
        body: JSON.stringify(data),
      })
        .then(this.handleErrors)
        .then((result) => {
          this.tableRows = []
          this.count = result.count
          this.kostylAdapter(result)
          this.waitingLoadClients = false
          this.$emit('clients-dashboard-count-update', this.count)
          $('#clients_count').text(`${t('found')}${this.count}`)
        })
        .catch((err) => {
          console.error(err)
          this.waitingLoadClients = false
          Services
            .ui
            .notificator()
            .error(T.abstract_error_message, T.error, 0)
        })
    },

    kostylAdapter (result) {
      result.clients.forEach((client, trIndex) => {
        this.tableRows[trIndex] = { option: '' }
        this.tableRows[trIndex].fullName = this.clientFullName(client)
        this.tableRows[trIndex].id = client.id
        this.tableRows[trIndex].reminder = client.reminder !== null ? client.reminder : ''
        this.tableRows[trIndex].birthDate = client.birthDate
        this.tableRows[trIndex].phone = client.phone
        this.tableRows[trIndex].email = client.email || ''
        this.tableRows[trIndex].paidSum = client.paidSum || '0'
      })
    },

    handleErrors (response) {
      if (!response.ok) throw Error(response.status)

      return response.json()
    },

    clientFullName (client) {
      return client.surname + ' ' + client.name + ' ' + client.secondName
    },

    activatePhonePopover (phone, event) {
      this.$pubSub.emitAsync('PHONE_POPOVER.SHOW', {
        phoneNumber: phone,
        clientId: event.target.dataset['clientId'],
        element: event.target,
      })
    },

    showUpdateReminderModal (reminder) {
      this.reminderModal.reminder = reminder
      this.reminderModal.action = 'update'
      this.reminderModal.visibility = true
    },

    showCreateReminderModal (clientId) {
      this.reminderModal.reminder = {
        title: '',
        date: new Date(),
        reminderType: 0,
        status: 0,
        clientId,
        responsibleIds: [],
        doctorId: null,
        clinicId: null,
        note: '',
        createdById: gon.application.current_user.id,
        updatedById: null,
        id: null,
      }
      this.reminderModal.action = 'create'
      this.reminderModal.visibility = true
    },

    reminderUpdated () {
      this.reminderModal.visibility = false
      this.getData()
    },
  },
}
</script>
