export default {
  init (options) {
    _rollUnrollListEvents(options)
    $('#reports_switch_btn').click(() => _showReportsList())
    $('#filters_switch_btn').click(() => _showFilters())
    $('#report_list_body').on('scroll', function () {
      if ($('#reports_switch_btn').hasClass('active')) {
        store.set('report_ui', { scrollPosition: this.scrollTop })
      }
    })

    if (store.get('report_ui') && store.get('report_ui').scrollPosition) {
      _setScrollPosition(store.get('report_ui').scrollPosition)
    } else {
      const scrollPosition = options.scrollPosition || 0
      store.set('report_ui', { scrollPosition })
      _setScrollPosition(scrollPosition)
    }

    if (options['hideFloatingPanel']) {
      $('#report_list_panel.floating').hide()
      $('.report_panel_primary .panel-heading-title').removeClass('padding-left')
      _showReportsList()
    } else {
      _showFilters()
    }

    // 7jrn59 - если во view у блока есть класс, то показываем report list
    // необходимо, если модуль не активирован
    if ($('#report_list_index').hasClass('js_unbought_class')) {
      _showReportsList()
    }

    _resizeBody()
    $(window).resize(() => _resizeBody())
  },
}

function _rollUnrollListEvents (options) {
  _setAndGetStoreValues(options)

  ////////

  $('.btn_roll_report_list').click(() => _rollPanel())

  $('.btn_unroll_report_list').click(() => _unrollPanel())

  $('.vertical-report-text').click(() => _floatingPanelToggle())

  $('#slim_btn_show_report_list').hover(() => {
    _showReportsList()
    _floatingPanelShow()
  }, () => { })

  $('#slim_btn_show_filters').hover(() => {
    _showFilters()
    _floatingPanelShow()
  }, () => { })

  _hidePanelOnClickOut()

  //uber hack
  $('body').on('mouseenter', '.select2-dropdown, .daterangepicker', () => {
    _floatingPanelShow()
  })
}

function _rollPanel () {
  $('#report_list_panel').hide()
  $('#report_list_panel_slim').show()
  _addFloatingEvents()
  store.set('report_ui', {report_list: 'rolled'})
}

function _unrollPanel () {
  $('#report_list_panel_slim').hide()
  $('#report_list_panel').removeClass('floating')
  $('#report_list_panel').show()
  $('.report-switch-container').show()
  $('.btn_roll_report_list').show()
  $('#report_list_panel').unbind('mouseleave')
  $('#report_list_panel .clickable-row').unbind('click')
  $('.report_panel_primary .panel-heading-title').removeClass('padding-left')
  store.set('report_ui', {report_list: 'unrolled'})
  _resizeBody()
}

function _floatingPanelShow () {
  $('#report_list_panel').show()

  if (store.get('report_ui') && store.get('report_ui').report_list == 'rolled') {
    $('.report_panel_primary .panel-heading-title').addClass('padding-left')
  }

  _resizeBody()
}

function _floatingPanelHide () {
  $('#report_list_panel').hide()
  $('.report_panel_primary .panel-heading-title').removeClass('padding-left')
}

function _floatingPanelToggle () {
  $('#report_list_panel').toggle()
  $('.report_panel_primary .panel-heading-title').toggleClass('padding-left')
}

function _showReportsList () {
  $('#filters_switch_btn').removeClass('active')
  $('#reports_switch_btn').addClass('active')
  $('#report_list_index').show()
  $('#menu_container').hide()
  $('#filters_heading').hide()
  $('#reports_list_heading').show()
  _setScrollPosition(store.get('report_ui').scrollPosition)
}

function _showFilters () {
  $('#reports_switch_btn').removeClass('active')
  $('#filters_switch_btn').addClass('active')
  $('#menu_container').show()
  $('#report_list_index').hide()
  $('#reports_list_heading').hide()
  $('#filters_heading').show()
}

function _addFloatingEvents () {
  $('.btn_roll_report_list').hide()
  $('.report-switch-container').hide()
  $('#report_list_panel').addClass('floating')
  $('#report_list_panel.floating .clickable-row').click(() => {
    $('#report_list_panel .panel-body').loadSpin('start')
    $('#report_list_panel').unbind('mouseleave')
  })
  $('#report_list_panel.floating').mouseleave(() => {
    _floatingPanelHide()
  })
}

function _setScrollPosition (scrollPosition) {
  $('#report_list_body').scrollTo(scrollPosition)
}

/////////////////////////////////////////////////////////////////////////////////

function _setAndGetStoreValues (options) {
  //JSON.parse(localStorage.historyBrowser)[1].match(/^\/reports\/.*$/)

  if (store.get('report_ui') == undefined) {
    store.set('report_ui', {})
  }

  if (store.get('report_ui').report_list == 'rolled') {
    $('#report_list_panel').hide()
    $('#report_list_panel_slim').show()
    _addFloatingEvents()
    $('#report_list_panel').show()
    $('.report_panel_primary .panel-heading-title').addClass('padding-left')
  } else {
    if (options['showList']) {
      _showReportsList()
    }
    if (options['showFilters']) {
      _showFilters()
    }
  }
}

function _resizeBody () {
  $('#report_list_body').height($('.main-container').height() - $('.report-switch-container').height() - 20)
  $('.floating #report_list_body').height($('.main-container').height() - $('.report-switch-container').height() - 15)
}

function _hidePanelOnClickOut () {
  $('.panel-body.report').on('click', function (e) {
    if (store.get('report_ui').report_list == 'rolled' && $(e.target).closest('#report_list_panel').length === 0) {
      _floatingPanelHide()
    }
  })
}
