/*
  Clickable <tr>.
   Add class='clickable-row' and data-href="#{some_item_path(some_item)}" in View to <tr>
*/

Utils.clickableTr = function () {
  $document.off('click.clickabletr')

  $document.on('click.clickabletr', '.clickable-row', function (e) {
    let scrolledContainer
    if ($(this).data('source') === 'sort_items') {
      scrolledContainer = $('.items-list-container').find('tbody')
    } else {
      scrolledContainer = $(this).closest('.panel-body')
    }
    let params = {}

    if (isActAsRegularRow()) {
      return
    }
    _preventDefaultBehavior(e)

    _savePanelBodyScrollPosition()
    _saveShowInternalCheckbox()

    if (!e.currentTarget.classList.contains('catalog-item')) {
      _savePaginationPageNumber()
    }
    _saveActiveTrId(this)

    const link = _buildUrl(this)
    this.dataset.turbolinks /* ожидается string */
      ? window.location.href = link
      : Turbolinks.visit(link)

    ///////////////////////////////////////////
    ///                                    ///
    //////////////////////////////////////////

    function _preventDefaultBehavior (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    function _savePanelBodyScrollPosition () {
      const scrolledContainerHasIdAttr = scrolledContainer && scrolledContainer.attr('id')
      if (scrolledContainerHasIdAttr) {
        params = $.extend(params, { scrollPositions: {} })
        params.scrollPositions[scrolledContainer.attr('id')] = scrolledContainer.scrollTop()
      } else {
        console.warn('Did not find ".panel-body" or ".panel-body" has no attr "id"')
      }
    }

    function _savePaginationPageNumber () {
      const page = gon.page.params.page
      if (page) params = $.extend(params, { page })
    }

    function _saveShowInternalCheckbox (self) {
      // edge case for catalogs/referrals page
      const showInternalRefs = $('#show_internal_refs')
      if (showInternalRefs) {
        params = $.extend(params, { with_internal: showInternalRefs.prop('checked') })
      }
    }

    function _saveActiveTrId (self) {
      if ($(self).attr('id')) {
        params.activeTr = $(self).attr('id')
      }
    }

    function _buildUrl (self) {
      if (_.isEmpty(params)) {
        return $(self).data('href')
      }

      return $(self).data('href') + '?' + $.param(params)
    }

    function isActAsRegularRow () {
      const ignoreClass = 'clickable-row-ignore'
      const target = $(e.target)

      return target.hasClass(ignoreClass) || target.closest('.' + ignoreClass).length
    }
  })
}

Utils.searchAndTurbolink = function (options = {}) {
  const item = options.item
  const model = options.model
  const path = options.route
  const pageNumberOptions = options.page_number_options || {}

  $.ajax({
    type: 'POST',
    url: Routes.utils_get_paginated_page_number_path(),
    data: {
      id: item.id,
      model,
      pageNumberOptions,
    },
    success (pageNumber) {
      const visitPath = path.call(this, item.id)
      const params = { page: pageNumber }
      params.activeTr = `${model}_${item.id}`
      Turbolinks.visit(visitPath + '?' + $.param(params))
    },
    error (err) {
      console.error(err)
    },
  })
}
