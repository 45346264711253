/*

 example link path: '/#order'
 add class for button: js_redirect_back

 */

window.HistoryBrowser = (function () {
  let update
  let updateVue
  let isAfterLogInPage

  let _rebindLinks
  let _goBackHandler
  let _goLastMatch

  let history = []

  // here regexps for parsing url as page names
  const r = {
    analysis_order: /^\/analysis_orders\/\d+$/,
    analysis_orders: /^\/analysis_orders(\?.+)?$/,
    app_configurations: /^\/app_configurations$/,
    client: /^\/clients\/\d+(#+.*)?(\?.+)?$/,
    client_areas_results: /^\/client_areas\/results$/,
    client_analysis_orders: /^\/clients\/\d+\/analysis_orders(\?+.*)?$/,
    client_documents: /^\/clients\/\d+\/documents(\?+.*)?$/,
    client_entries: /^\/clients\/\d+\/entries(\?+.*)?$/,
    client_medical_policies: /^\/clients\/\d+\/medical_policies(\?+.*)?$/,
    client_orders: /^\/clients\/\d+\/orders(\?+.*)?$/,
    client_treatment_plans: /^\/clients\/\d+\/treatment_plans(\?+.*)?$/,
    client_dental_orders: /^\/clients\/\d+\/dental_orders(\?+.*)?$/,
    client_payments: /^\/clients\/\d+\/payments(\?+.*)?$/,
    clients: /^\/clients(\?.+)?$/,
    clinic: /^\/clinics\/\d+$/,
    clinics: /^\/clinics$/,
    company: /^\/companies\/\d+(#+.*)?(\?.+)?$/,
    companies: /^\/companies(\?.+)?$/,
    companies_documents: /^\/companies\/\d+\/documents(\?+.*)?$/,
    egisz: /^\/egisz(\?.+)?$/,
    egisz_clients: /^\/egisz\/clients(\?.+)?$/,
    egisz_semds: /^\/egisz\/semds(\?.+)?$/,
    dashboard: /^\/$/,
    dental_order: /^\/dental_orders\/\d+$/,
    dental_orders: /^\/dental_orders(\/.*)?$/,
    doctor_areas: /^\/doctor_areas$/,
    doctor_areas_schedule: /^\/doctor_areas\/schedule$/,
    doctor_schedules: /^\/doctor_schedules$/,
    document: /^\/documents\/\d+$/,
    documents: /^\/documents(\?+.*)?$/,
    entries: /^\/entries(\?.+)?$/,
    entry: /^\/entries\/\d+$/,
    entry_results: /^\/entry_results(\?.+)?$/,
    expense_group: /^\/expense_groups\/\d+(\?.+)?$/,
    expense_groups: /^\/expense_groups(\?.+)?$/,
    fiscal_printer: /^\/workplaces\/\d+\/fiscal_printer/,
    list_of_document_types: /^\/documents\/list_of_document_types.*$/,
    loggings: /^\/trash_bins\/loggings$/,
    medical_policy: /^\/medical_policies\/\d+(#+.*)?$/,
    medical_record: /^\/medical_records\/\d+(\?+.*)?(#+.*)?$/,
    medical_record_entry: /^\/medical_records\/\d+\/\d+(\?+.*)?(#+.*)?$/,
    medical_record_print: /^\/medical_records\/\d+\/print.*$/,
    order: /^\/orders\/\d+$/,
    orders: /^\/finance\/orders(\?.+)?$/,
    payment: /^\/payments\/\d+(\?.+)?$/,
    payments: /^\/finance\/payments(\?.+)?$/,
    adjust_scheduler: /^\/adjust_scheduler(\?.+)?$/,
    print_appointments: /^\/adjust_scheduler\/print_appointments(\?.+)?$/,
    print_scheduler: /^\/adjust_scheduler\/print_scheduler(\?.+)?$/,
    profession: /^\/professions\/\d+$/,
    professions: /^\/professions$/,
    receipt_good: /^\/receipt_goods\/\d+$/,
    receipt_goods: /^\/receipt_goods(\?+.*)?$/,
    receipt_goods_cancellations: /^\/receipt_goods\/cancellations$/,
    receipt_goods_receipts: /^\/receipt_goods\/receipts$/,
    reports: /^\/reports\/.*$/,
    store: /^\/stores\/\d+$/,
    stores: /^\/stores(\?+.*)?$/,
    treatment_plan: /^\/treatment_plans\/\d+$/,
    treatment_plans: /^\/treatment_plans(\?+.*)?$/,
    user: /^\/users\/\d+$/,
    user_worktime: /^\/users\/\d+\/worktime$/,
    users: /^\/users(\?+.*)?$/,
    workplace: /^\/workplaces\/\d+$/,
    workplaces: /^\/workplaces$/,
  }

  const rr = {
    order_edit: '/orders/edit',
  }

  Object.keys(rr).forEach(function (key) {
    const raw = rr[key]
    const decorated = '^' + raw + '/?' + '(\\?.+)?' + '(#+.*)?' + '$'
    r[key] = new RegExp(decorated)
  })

  const allExcept = function (elems) {
    const res = []
    for (const pat in r) {
      if (elems.indexOf(r[pat]) === -1) res.push(r[pat])
    }

    return res
  }

  // here is lists of pages for redirects from
  // key - from, value - массив доступных переходов
  const patterns = {
    analysis_order: [
      r.analysis_orders,
      r.order,
      r.client_analysis_orders,
    ],
    analysis_order_edit: [
      r.analysis_order,
    ],
    dental_order: [
      r.dental_orders,
      r.order,
      r.client_dental_orders,
      r.medical_record,
    ],
    dental_order_new: [
      r.order,
    ],
    dental_order_edit: [
      r.dental_order,
    ],
    order: [
      r.orders,
      r.dashboard,
      r.doctor_schedules,
      r.client,
      r.company,
      r.loggings,
      r.client_orders,
      r.payment,
      r.client_payments,
      r.dental_order,
    ],
    order_edit: [
      r.order,
      r.orders,
    ],
    entry: [
      r.client,
      r.company,
      r.medical_record,
      r.order,
      r.entry,
      r.entry_results,
      r.analysis_order,
      r.medical_record_entry,
      r.client_areas_results,
      r.client_entries,
      r.payment,
    ],
    entry_edit: [
      r.entry,
      r.entry_results,
      r.medical_record,
      r.medical_record_entry,
      r.egisz_semds,
    ],
    client: [
      r.order,
      r.clients,
      r.medical_record,
      r.reports,
      r.doctor_schedules,
      r.dashboard,
      r.entry,
      r.medical_policy,
      r.payment,
      r.client_payments,
      r.company,
    ],
    client_medical_policy_guarantee_letter: [
      r.medical_policy,
    ],
    client_medical_policy_guarantee_letter_form: [
      r.medical_policy,
    ],
    medical_policy: [r.client_medical_policies],
    companies: [r.companies, r.company, r.app_configurations],
    user: [r.users, r.doctor_schedules, r.dashboard, r.payment, r.workplaces],
    user_worktime: [r.doctor_schedules, r.adjust_scheduler, r.user, r.users, r.doctor_areas],
    clinic: [r.clinics, r.app_configurations],
    clinics: [r.app_configurations],
    conclusion: [r.medical_record, r.client, r.order],
    edit_conclusion: [r.medical_record, r.order, r.conclusion],
    harmful_factor: [r.harmful_factors, r.profession],
    document: [
      r.list_of_document_types,
      r.order,
      r.dental_order,
      r.client,
      r.company,
      r.client_documents,
      r.medical_record,
      r.doctor_areas,
      r.companies_documents,
      r.document,
      r.dental_orders,
    ],
    store: [r.stores],
    treatment_plan: [
      r.client_treatment_plans,
      r.medical_record],
    receipt_good: [
      r.receipt_goods,
      r.receipt_goods_receipts,
      r.receipt_goods_cancellations,
    ],
    medical_record: [
      r.client,
      r.doctor_areas,
      r.doctor_areas_schedule,
      r.reports,
    ],
    list_of_document_types: [r.order, r.client, r.company],
    be_calculation: [r.be_calculations],
    workplace: [r.workplaces, r.user],
    fiscal_printer: allExcept([]),
    payment: [r.order, r.client_payments, r.payments, r.company],
    new_payment: [r.client_payments, r.order, r.company],
    medical_record_print: [r.medical_record, r.order, r.client_documents],
    print_scheduler: [r.adjust_scheduler, r.doctor_schedules],
    print_appointments: [r.adjust_scheduler, r.doctor_schedules],
    adjust_scheduler: [r.doctor_schedules],
    expense_groups: [r.expense_groups, r.expense_group],
    fixedDocumentDestroy: [
      r.client_documents,
      r.medical_record,
    ],
  }

  const clear = function () {
    sessionStorage.historyBrowser = JSON.stringify([])
    history = []
    store()
  }

  update = function () {
    _rebindLinks()

    // take history from sessionStorage on page reload
    if (history.length === 0 && sessionStorage.historyBrowser) {
      history = JSON.parse(sessionStorage.historyBrowser)
    }
    // slim down history
    if (history.length > 100) history.splice(-50)

    const currentPage = window.location.pathname +
      window.location.search +
      window.location.hash

    if (currentPage !== history[0]) history.unshift(currentPage)

    store()
  }

  updateVue = function (newRoute) {
    // take history from sessionStorage on page reload
    if (history.length === 0 && sessionStorage.historyBrowser) {
      history = JSON.parse(sessionStorage.historyBrowser)
    }
    // slim down history
    if (history.length > 100) history.splice(-50)

    if (newRoute !== history[0]) history.unshift(newRoute)

    store()
  }

  isAfterLogInPage = function () {
    if (history[1] === '/users/sign_in') return true

    return false
  }

  var store = function () {
    sessionStorage.historyBrowser = JSON.stringify(history)
  }

  _rebindLinks = function () {
    $('.js_redirect_back').off('click.historyBrowser')
    $('.js_redirect_back').on('click.historyBrowser', _goBackHandler)
  }

  const goLastMatch = (from) => {
    const pattern = patterns[from]
    if (!pattern) throw new Error('patterns not found for ' + from)

    return _goLastMatch(pattern)
  }

  const goBackFrom = function (from) {
    return goLastMatch(from)
  }

  _goBackHandler = function (e) {
    e.preventDefault()
    e.stopPropagation()
    let from = $(this).attr('href')
    from = from.replace(/\/#/, '')

    if (!goLastMatch(from)) {
      console.info('Redirect to doctor_schedules')
      setTimeout(() => { Turbolinks.visit(Routes.doctor_schedules_path()) }, 500)
    }
  }

  _goLastMatch = function (patterns) {
    const hl = history.length
    let found = false
    const current = history[0]
    for (var i = 1; i < hl; i++) {
      patterns.forEach(function (pattern) {
        const url = history[i]
        if (!found && pattern.test(url) && current !== url) {
          history.splice(0, i)
          store()
          found = true
          Turbolinks.visit(url)
        }
      })

      if (found) break
    }

    return found
  }

  return {
    update,
    updateVue,
    clear,
    goBackFrom,
    isAfterLogInPage,
  }
})()
