import { colorsMap } from '@/modules/utils/medods_ui/colorsMap'
import { iconsMap } from '@/modules/utils/medods_ui/iconsMap'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

window.MedodsUI = (function () {
  const ui = {}

  ui.colorsMap = colorsMap
  ui.iconsMap = iconsMap

  ui.MConfirm = MConfirm

  return ui
}())
