/**
 * Описание класса в ./README.md
 */
export class Intent {
  /**
   * @see {Intent.getIntent}
   * @param {string[]} [types]
   * @return {{payload: *, type: string}|undefined}
   */
  static getCurrentIntent (types) {
    return Intent.getIntent(window, types)
  }

  /**
   * Устанавливает intent для окна
   *
   * @param {Window} window
   * @param {string} type
   * @param {any} [payload]
   */
  static setIntent (window, type, payload) {
    if (window.__INTENT__) {
      console.error('The `__INTENT__` property can be set for window only once.')
    }

    Object.defineProperty(window, '__INTENT__', {
      enumerable: false,
      configurable: false,
      writable: true,
      value: Object.freeze({
        type,
        payload,
      }),
    })
  }

  /**
   * @param {Window} window
   * @param {string[]} types
   * @return {{payload: any, type: string}|undefined}
   */
  static getIntent (window, types) {
    const intent = window.__INTENT__

    if (intent && types && types.length) {
      return types.includes(intent.type) ? intent : undefined
    }

    return intent
  }

  static removeIntent (__window = window) {
    if (!__window.__INTENT__) { return }
    __window.__INTENT__ = undefined
  }
}

Intent.SCHEDULE_APPOINTMENT_EDIT = 'schedule/appointment/edit'
Intent.SCHEDULE_APPOINTMENT_MOVE = 'schedule/appointment/move'
Intent.SCHEDULE_WAITING_CREATE = 'schedule/waiting_list/create'
Intent.SCHEDULE_APPOINTMENT_CREATE = 'schedule/appointment/create'
