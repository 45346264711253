import { INCOMING_CALL_TYPE, OUTCOMING_CALL_TYPE } from './const.js'

import { callModalColors, callNotificationsIcons } from './css_classes.js'
import { Intent } from '@/services/intents'
import { request } from '@/lib/transport/request'
import { createStore } from '@/vue_components/store/store'

window.CallModule = (function () {
  const store = createStore()
  let
    init,
    show,
    _update,
    _getCallData,
    _bindEvents,
    _fromDoctorSchedules,
    _fromOutside,
    _openModal,
    _bindGeneralEvents,
    editAppointment,
    clear

  let
    $modal

  let
    client,
    attractionSource,
    callPhone,
    callEndedAt,
    callId,
    appointmentStore,
    appointmentForm,
    initialized

  init = function (params) {
    if (initialized || !gon.application.current_user) return
    params = params || {}
    $modal = $('#modal_call_show')
    appointmentStore = {}
    appointmentForm = params.appointmentForm

    $modal.viewInModal({
      template: $('#handlebars_call_show').html(),
    })
    _bindEvents()
    initialized = true
  }

  clear = function () {
    initialized = false
  }

  const getCallId = () => {
    const timeAfterCall = moment().diff(callEndedAt, 'seconds')
    const callLookupDuration = gon.application.call_lookup_duration

    if (timeAfterCall < callLookupDuration || !callEndedAt) {
      return callId
    }
  }

  _getCallData = function (id) {
    return request({
      type: 'GET',
      url: Routes.call_path(id),
    }).promise
      .then((data) => {
        appointmentStore = {}
        callPhone = data.phone
        callEndedAt = data.ended_at
        client = data.client
        attractionSource = data.attraction_source_id
        if (client) {
          data.client.last_appointments.forEach(function (app) {
            appointmentStore[app.id] = app
          })
        }

        return data
      }).catch((err) => {
        console.error(err)
      })
  }

  _update = function (id) {
    _getCallData(id).then((data) => { $modal.viewInModal('update', data) })
  }

  show = function (id) {
    callId = id

    _getCallData(id).then(_openModal)
  }

  _openModal = function (data) {
    const modalHeader = $modal.find('.modal-header')
    if (data.call_type === OUTCOMING_CALL_TYPE) {
      modalHeader.removeClass(callModalColors[INCOMING_CALL_TYPE])
    } else {
      modalHeader.removeClass(callModalColors[OUTCOMING_CALL_TYPE])
    }
    if (!modalHeader.hasClass(callModalColors[data.call_type])) {
      modalHeader.addClass(callModalColors[data.call_type])
    }
    const iconElement = document.createElement('span')
    $(iconElement).addClass(callNotificationsIcons[data.call_type])
    $modal.find('.modal-title').text(t('telephony.' + data.call_type + '_call')).prepend(iconElement)
    $modal.viewInModal('show', data)
    PubSub.emit('page.specific.call_modal.shown')
  }

  _bindEvents = function () {
    const pageName = gon.page.controller

    _bindGeneralEvents()
    pageName === 'doctor_schedules' ? _fromDoctorSchedules() : _fromOutside()
  }

  _fromDoctorSchedules = function () {
    $modal.on('click', '.schedule-grid-appointment-move', function () {
      const id = $(this).data('id')
      const app = appointmentStore[id]

      store.dispatch('moveAppointmentFromCall', app)

      $modal.modal('hide')
    })

    $modal.on('click', '.add_appointment', function () {
      const params = client ? { clientId: client.id } : { clientPhone: callPhone }
      params.attractionSourceId = client?.attraction_source_id || attractionSource
      params.callId = getCallId()

      store.dispatch('createAppointmentFromOutside', params)
      $modal.modal('hide')
    })

    $modal.on('click', '.add_to_waiting_list', function () {
      if (client) {
        store.dispatch('createWaitingItemFromCall', { id: client.id })
      } else {
        store.dispatch('createWaitingItemFromCall', { phone: callPhone })
      }
      $modal.modal('hide')
    })

    $modal.on('click', '.schedule-grid-appointment-edit', function () {
      const id = $(this).data('id')
      const appointment = appointmentStore[id]
      store.dispatch('editAppointmentFromCall', appointment)
      $modal.modal('hide')
    })
  }

  _fromOutside = function () {
    $modal.on('click', '.schedule-grid-appointment-move', function () {
      const id = $(this).data('id')
      const app = appointmentStore[id]
      const url = Routes.doctor_schedules_path()
      const newWindow = window.open(url, '_blank')
      Intent.setIntent(
        newWindow,
        Intent.SCHEDULE_APPOINTMENT_MOVE,
        { appointment: app }
      )
    })
    // add client to waiting list
    $modal.on('click', '.add_to_waiting_list', function () {
      const url = Routes.doctor_schedules_path()
      const data = client ? { id: client.id } : { phone: callPhone }
      const newWindow = window.open(url, '_blank')

      Intent.setIntent(
        newWindow,
        Intent.SCHEDULE_WAITING_CREATE,
        data
      )
    })

    $modal.on('click', '.schedule-grid-appointment-edit', function () {
      const id = $(this).data('id')
      const app = appointmentStore[id]
      const url = Routes.doctor_schedules_path()
      const newWindow = window.open(url, '_blank')
      Intent.setIntent(
        newWindow,
        Intent.SCHEDULE_APPOINTMENT_EDIT,
        { appointment: app }
      )
    })

    $modal.on('click', '.add_appointment', function () {
      const params = client ? { clientId: client.id } : { clientPhone: callPhone }
      params.attractionSourceId = client?.attraction_source_id || attractionSource
      params.callId = getCallId()

      const newWindow = window.open(Routes.doctor_schedules_path(), '_blank')
      Intent.setIntent(
        newWindow,
        Intent.SCHEDULE_APPOINTMENT_CREATE,
        params
      )
    })
  }

  _bindGeneralEvents = function () {
    $modal.on('click', '.schedule-grid-appointment-cancel', function () {
      const id = $(this).data('id')
      const app = appointmentStore[id]
      const msg = t('reception.do_you_really_want_to_cancel_appointment')

      bootbox.confirmYN(msg, function (res) {
        if (!res) return

        $modal.viewInModal('freeze')

        $.ajax({
          url: Routes.appointment_path(app.id),
          method: 'PATCH',
          data: { appointment: { status: 'canceled' } },
          success (app) {
            _update(callId)
          },
          error (err) {
            console.error(err)
            $modal.viewInModal('unfreeze')
          },
        })
      })
    })

    $modal.on('click', '.make_yourself_responsible', function () {
      $.ajax({
        method: 'PATCH',
        url: Routes.call_path(callId),
        data: {
          call: {
            responsible_ids: [gon.application.current_user.id],
          },
        },
        success (data) {
          appointmentStore = {}
          callPhone = data.phone
          client = data.client
          attractionSource = data.attraction_source_id
          if (client) {
            data.client.last_appointments.forEach(function (app) {
              appointmentStore[app.id] = app
            })
          }
          _openModal(data)
          Notificator.success(t('youre_responsible_now'))
        },
        error (err) {
          if (err.message) {
            Notificator.error(err)
          }
          console.error(err)
        },
      })
    })

    $modal.on('change', '#client_selector', function () {
      const newClientId = parseInt($(this).val())

      if (client.id !== newClientId) {
        $.ajax({
          method: 'PATCH',
          url: Routes.call_path(callId),
          data: {
            call: {
              client_id: newClientId,
            },
          },
          success (data) {
            appointmentStore = {}
            callPhone = data.phone
            client = data.client
            attractionSource = data.attraction_source_id
            if (client) {
              data.client.last_appointments.forEach(function (app) {
                appointmentStore[app.id] = app
              })
            }
            _openModal(data)
            Notificator.success(t('telephony.client_has_been_changed'))
          },
          error (err) {
            if (err.message) {
              Notificator.error(err)
            }
            console.error(err)
          },
        })
      }
    })
  }

  editAppointment = function (app) {
    appointmentForm.editItem(app)
  }

  return {
    show,
    init,
    clear,
    editAppointment,
  }
})()
